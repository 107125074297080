import * as jointjs from "@joint/plus";
import {AC_RECT_LABEL, DO_RECT_LABEL} from "../ApplicationComponentPaperConstants";
import Logger from "../../../../../utils/Logger";
import {setDataObjectNodeToElement} from "../ViewTransformers";

const LOGGER = new Logger("customShapes")


jointjs.shapes.standard.custom = {}

function computeFontSize(rectWidth, rectHeight, text) {
    const context = document.createElement('canvas').getContext('2d');

    let fontSize = rectHeight;  // initial value
    context.font = `${fontSize}px Arial`;

    while (context.measureText(text).width > rectWidth && fontSize > 0) {
        fontSize -= 1;  // decrease the font size
        context.font = `${fontSize}px Arial`;
    }

    return fontSize;
}

function createDataObjectRectangle(parentPosition, startY, width, dataObjectNode, applicationRectangle, index, doHeightJump, borderColor="black") {
    const fontSize = computeFontSize(width - DO_RECT_LABEL.paddingSides * 2, DO_RECT_LABEL.height, (dataObjectNode?.name || "-"))
    let mdRectangle = new jointjs.shapes.standard.Rectangle({

        position: {
            x: parentPosition.x + DO_RECT_LABEL.paddingSides,
            y: parentPosition.y + AC_RECT_LABEL.height + index * doHeightJump + startY
        },
        size: {width: width - 2 * DO_RECT_LABEL.paddingSides, height: DO_RECT_LABEL.height},
        magnet: false,

        attrs: {
            fill: 'white',
            body: {
                fill: '#9fc9f5',
                stroke: borderColor,
                strokeWidth: 1,
            },
            label: {
                fontSize: Math.min(fontSize, DO_RECT_LABEL.defaultFontSize),
                text: (dataObjectNode?.name || "ERROR"),
                textWrap: {
                    width: DO_RECT_LABEL.width - (2 * DO_RECT_LABEL.paddingSides),
                    height: DO_RECT_LABEL.height, // no height restriction
                }
            },
        },
    });
    mdRectangle.set('z', 5)

    setDataObjectNodeToElement(mdRectangle, dataObjectNode)

    return mdRectangle
}

jointjs.shapes.standard.custom.ApplicationRectangle = jointjs.shapes.standard.Rectangle.define("standard.custom.ApplicationRectangle",{
        applicationNode: undefined,
        mds: [], // An array of master objects
        sds: [], // An array of secondary objects
        dataAttrs: {     // Default attributes for child rectangles
            paddingSides: 5,
            width: 100,
            height: 30,
            fill: 'lightgray',
            label: {
                height: AC_RECT_LABEL.height,
                paddingHeight: 5,
                paddingSides: 5,
                defaultFontSize: 12,
            }
        },
        appAttrs: {
            label: {
                height: 40,
                defaultFontSize: 12,
            }
        },
        attrs: {
            fill: 'lightblue',
            stroke: 'gray',
            strokeWidth: 1,
            body: {
                fill: 'none',
                stroke: 'skyblue',
                strokeWidth: 1,
            },

        }
    } , {
    initialize: function () {

        LOGGER.debug("ApplicationRectangle created")
        jointjs.shapes.standard.Rectangle.prototype.initialize.apply(this, arguments);

        const applicationNode = this.get("applicationNode")
        const mds = this.get("mds")
        const sds = this.get("sds")

        const numberOfDataObjects = mds.length + sds.length
        const numberOfMasterDataObjects = mds.length

        const doHeightJump = DO_RECT_LABEL.height + DO_RECT_LABEL.paddingHeight

        this.size(100, Math.max(numberOfDataObjects*doHeightJump + AC_RECT_LABEL.height + DO_RECT_LABEL.paddingHeight, 50))


        this.attr("label/color", "yellow")
        this.attr("label/text", ((applicationNode?(applicationNode?.name):"XXX")))


        if (!numberOfDataObjects>0) {
            this.attr('label/textVerticalAnchor', 'middle');
            this.attr('label/textAnchor', 'middle');
            this.attr('label/refX', 0.5);
            this.attr('label/refY', 0.5);
        } else {
            // If there are childObjects, set the label to the top of the rectangle
            this.attr('label/textVerticalAnchor', 'top');
            this.attr('label/textAnchor', 'middle');
            this.attr('label/refX', 0.5);
            this.attr('label/refY', 10); // Adjust this value as needed to provide some padding from the top
        }

        this.attr("label/textWrap", {
            width: this.get('size').width - 20, // reference width minus 10
            height: AC_RECT_LABEL.height, // height restriction
            ellipsis: true,
        })


        let theParent = this
        theParent.set('z', 0)

        mds.forEach((md, index)=> {
            const mdRect = createDataObjectRectangle(theParent.attributes.position, 0, this.get("size").width,md, this, index, doHeightJump, "#222222")
            theParent.embed(mdRect)
        })

        sds.forEach((sd, index) => {
            const sdRect = createDataObjectRectangle(theParent.attributes.position, numberOfMasterDataObjects*doHeightJump, this.get("size").width, sd, this, index, doHeightJump, "#AAAAAA");
            theParent.embed(sdRect)
        })
    },
});
