import {NodeType} from "../../../../model/Constants";
import Logger from "../../../../utils/Logger";
import {findElementsWithAttribute, showDialog} from "./JointjsUtils";
import * as htmlToImage from "html-to-image";
import {debounce} from "lodash";

const LOGGER = new Logger("DiagramUtils")

function saveFunctionToDebounce(saveNode, node, graphJSON) {

    if (!node) {
        LOGGER.debug("node is undefined, can't save")
        return
    }

    if (!graphJSON) {
        LOGGER.debug("graphJSON is undefined, can't save")
        return
    }

    LOGGER.debug("onSaveHandler called")
    node.graph = graphJSON
    saveNode(node)
}

export const saveGraphJSON = debounce(saveFunctionToDebounce, 500)

export function checkIdsMatch(node, nodeId) {
    return node?.id === nodeId
}

export function getChildCapabilities(searchNodes, capability) {
    return searchNodes((c)=>c.parentId===capability?.id)
}
export function getSupportingApplications(searchNodes, capability) {
    return searchNodes((n)=>n.type === NodeType.Application.description && n.supportedCapabilityIds?.includes(capability?.id))
}

export function findApplicationComponentRectangles(graph, applicationId) {
    return findElementsWithAttribute(graph, "applicationId", applicationId)
}

export function snapPointToGrid(x,y,gridSizeX, gridSizeY) {

    const hx = Math.floor(gridSizeX/2)
    const hy = Math.floor(gridSizeY/2)

    const px = x%gridSizeX
    const py = y%gridSizeY

    return {
        x: Math.floor(x/gridSizeX)*gridSizeX + (px<=hx?0:gridSizeX),
        y: Math.floor(y/gridSizeY)*gridSizeY + (py<=hy?0:gridSizeY),
    }

}

export function getApplicationMasterData(getNodeById, applicationNodeId) {
    const node = getNodeById(applicationNodeId)
    if (node?.type !== NodeType.Application.description) {
        LOGGER.debug("getApplicationMasterData called with node of wrong type. Expecting 'Application', but got:", node?.type)
        return []
    }

    //TODO something's fishy here: xxx
    let masterDataObjects = node.masterDataObjectIds?.map(mdoId=> {
        const mdo = getNodeById(mdoId)
        return mdo
    })

    if (!masterDataObjects) {
        masterDataObjects = []
    }

    return masterDataObjects
}

export function getApplicationSecondaryData(getNodeById, searchNodes, applicationNodeId) {
    const applicationNode = getNodeById(applicationNodeId)
    if (applicationNode?.type !== NodeType.Application.description) {
        LOGGER.debug("getApplicationSecondaryData called with node of wrong type. Expecting 'Application', but got:", applicationNode?.type)
        return []
    }


    let incomingDataFlows = searchNodes((n)=>n.type===NodeType.DataFlow.description && n.targetApplicationId === applicationNodeId)

    //TODO Filter out doubles

    const idsArray = incomingDataFlows?.flatMap((df)=>df.dataObjectIds)
    const uniqueIdsArray = [...new Set(idsArray)];

    let secondaryDataObjects = uniqueIdsArray?.filter(doId=>{
        let masterDataObjectIds = applicationNode?.masterDataObjectIds
        if (!masterDataObjectIds) {
            LOGGER.debug("masterDataObjectIds is undefined, and so the Data Object is a secondary object :)")
            return true
        }
        if (!Array.isArray(masterDataObjectIds)) {
            LOGGER.debug("getApplicationSecondaryData called with node of wrong type. Expecting 'Application', but got:", applicationNode?.type)
            return false
        }
        return !masterDataObjectIds?.includes(doId)
    })?.map(doId=>getNodeById(doId))


    if (!secondaryDataObjects) {
        secondaryDataObjects = []
    }

    return secondaryDataObjects
}

export function getApplicationTree(getNodeById, application) {

    let appTree = {
        application,
    }

    return appTree

}

export function getCapabilityTree(getNodeById, searchNodes, capability, includeSupportingApplications) {

    const childCapas = getChildCapabilities(searchNodes, capability).map(capa=>getCapabilityTree(getNodeById, searchNodes, capa, includeSupportingApplications))
    let supportingApplications = []
    if (includeSupportingApplications) {
        supportingApplications = getSupportingApplications(searchNodes, capability)
        if (supportingApplications?.length > 0) {
            supportingApplications = supportingApplications.map(app=>getApplicationTree(getNodeById, app))
        }
    }

    let capaNodeTree = {
        capability,
        supportingApplications,
    }
    if (childCapas?.length > 0) {
        capaNodeTree.childCapabilities = childCapas
    }
    if (supportingApplications?.length > 0) {
        capaNodeTree.supportingApplications = supportingApplications
    }

    return capaNodeTree

}

// Utility to trigger the download
export function download(href, name) {
    const link = document.createElement('a');
    link.download = name;
    link.href = href;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

export function getElementsUnderElement(graph, paper, element) {
    if (!graph) {
        return [];
    }

    return graph.findModelsUnderElement(element, {
        searchBy: paper?.options?.findParentBy
    });
}

export function getNewEmbeds(elementsUnder, element) {
    if (element.isEmbedded()) return [];
    return elementsUnder.filter((el) => {
        if (el.isEmbedded()) return false;
        return true;
    });
}

export function centerContentOnDiagram(paper) {
    const paperArea = paper.getArea();
    const contentArea = paper.getContentArea();

    paper.translate((paperArea.width - contentArea.width) / 2, (paperArea.height - contentArea.height) / 2);
}
export function centerCellOnDiagram(paper, cell) {
    const paperArea = paper.getArea();
    const cellSize = cell.size();

    paper.translate((paperArea.width - cellSize.width) / 2, (paperArea.height - cellSize.height) / 2);
}

export async function downloadAsPngImage(elementReference, filename) {

    if (!elementReference) {
        showDialog({
            text: "No elementReference provided to downloadAsPngImage",
            buttons: [{
                id: "close",
                text: "Close",
                handler: () => {},
                sameAsClose: true,
            }],
            selectOptions: [],
            isMultiSelect: false,
        })
        return
    }

    const dataUrl = await htmlToImage.toPng(elementReference);

    // download image
    const link = document.createElement('a');
    link.download = (filename?filename:"html-to-img.png");
    link.href = dataUrl;
    link.click();

}
