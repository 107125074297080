import {forwardRef, useEffect, useImperativeHandle, useMemo, useRef} from "react";
import cssStyles from "./GridStackComponent4.module.css"
import Logger from "../../../utils/Logger";
import {NodeType} from "../../../model/Constants";
import {
    centerCellOnDiagram,
    getApplicationTree,
    getCapabilityTree,
    getElementsUnderElement,
    getNewEmbeds, saveGraphJSON
} from "./utils/DiagramUtils";
import {EMPTY_GRAPH, fitElementsToPaper, getCustomProp, hasType} from "./utils/JointjsUtils";
import * as jointjs from "@joint/plus";
import {isCapability, resizeCapability} from "./GridStackComponent4_utils";
import {elementView_pointermove} from "./GridStackComponent4_Handlers";
import {
    createApplicationRectangle, createCapabilityRectangle,
    updateApplicationRectangle,
    updateCapabilityRectangle
} from "./GridStackComponent4_ShapeFactory";
import {useSelectedNodes} from "../../SelectedNodes/SelectedNodesProvider";
import {useModel} from "../../../model/ModelContext";

const LOGGER = new Logger("GridStackComponent4", 1)

function acceptableType(node) {
    return node.type === NodeType.Application.description || node.type === NodeType.Capability.description
}

//TODO when a child is resized, then the containing parent should be resized to fit the resized child
//TODO implement save method





function refreshPaper(paper, getNodeById) {
    paper?.model?.getCells()?.forEach(cell => {
        if (hasType(cell, NodeType.Application.description)) {
            const applicationId = getCustomProp(cell, "applicationId")
            const applicationNode = getNodeById(applicationId)
            updateApplicationRectangle(paper, getNodeById, applicationNode, cell);

        } else if (hasType(cell, NodeType.Capability.description)) {
            const capabilityId = getCustomProp(cell, "capabilityId")
            const capabilityNode = getNodeById(capabilityId)
            updateCapabilityRectangle(paper, getNodeById, capabilityNode, cell);
        }
    })
    fitElementsToPaper(paper)
}

function createNewGraphNode(getNodeById, searchNodes, node, paper, x, y, shouldIncludeApplications) {
    switch (node?.type) {
        case NodeType.Application.description:
            const appTree = getApplicationTree(getNodeById, node)
            return createApplicationRectangle(paper, getNodeById, appTree, x, y)
        case NodeType.Capability.description:
            const capaTree = getCapabilityTree(getNodeById, searchNodes, node, shouldIncludeApplications)
            return createCapabilityRectangle(paper, getNodeById, capaTree, x, y)
        default:
            return
    }
}

function GridStackComponent4({
                                node,
                                nodeBeingDragged,
                                setNodeBeingDragged,
                                addChildNode,
                                tileSelected,
                                snapToGrid,
                                gridSize,
                                shouldIncludeApplications,
                            }, ref) {

    const {getNodeById, searchNodes, saveNode} = useModel()

    const namespace = jointjs.shapes

    const highlighterId = "embedding";

    const highlighterOptions = useMemo(() => {
        return {
            padding: 2,
            attrs: {
                "stroke-width": 3,
                stroke: "#7c68fc"
            }
        }
    }, [])

    const {setSoftSelectedNodeById} = useSelectedNodes()

    let paperRef = useRef(null)
    let navigatorRef = useRef(null)

    let graph = useRef(null)
    let paper = useRef(null)
    let scroller = useRef(null)

    useImperativeHandle(ref, ()=>{
        return ({
            getPaper:()=>{return paper.current},
            clearPaper:()=>{
                graph.current.clear()
            },
            addNode:(node, x, y)=>{
                LOGGER.debug(`addNode.node/x/y: ${JSON.stringify(node || {})}/${x}/${y}`)
                if (!node) {
                    LOGGER.debug("addNode.node is undefined")
                    return
                }
                if (!acceptableType(node)) {
                    LOGGER.debug("addNode.node is not of acceptable type, got: ", node?.type)
                    return
                }

                const paperCoordinates = paper.current.clientToLocalPoint(x, y)

                const newElement = createNewGraphNode(getNodeById, searchNodes, node, paper.current,  paperCoordinates.x, paperCoordinates.y, shouldIncludeApplications)

                if (!newElement) {
                    LOGGER.debug("addNode.newElement is undefined")
                    return
                }
                newElement.addTo(graph.current)



                centerCellOnDiagram(paper.current, newElement)
                //node.graph = graph.current.toJSON()
                //TODO onSaveNodeHandler(node)
                LOGGER.debug("updated node=", node)
            }
        })
    })



    useEffect(() => {

        if (!paperRef?.current) {
            return
        }

        /*
        the following code is largely based on
        - the demo https://www.jointjs.com/demos/hierarchical-diagrams

        */

        graph.current = new jointjs.dia.Graph({}, {cellNamespace: namespace})
        let paperOptions = {
            async: true,
            autoResizePaper: true,
            cellViewNamespace: namespace,
            clickThreshold: 10,
            cursor: 'grab',
            drawGrid: true,
            embeddingMode: true,
            frontParentOnly: false,
            gridSize: 10,
            //height: "100%",
            highlighting: {
                embedding: {
                    name: "mask",
                    options: highlighterOptions
                }
            },
            inertia: true,
            model: graph.current,
            scrollWhileDragging: true,
            sorting: jointjs.dia.Paper.sorting.APPROX,
            validateEmbedding: (childView, parentView) => isCapability(parentView.model),
            //width: "100%",
        }
        if (snapToGrid) {
            paperOptions.gridSize= 5
        }
        paper.current = new jointjs.dia.Paper(paperOptions);

        scroller.current = new jointjs.ui.PaperScroller({
            paper: paper.current,
            autoResizePaper: true,
            cursor: 'grab',
            scrollWhileDragging: true,
            inertia: true
        });

        const nav = new jointjs.ui.Navigator({
            paperScroller: scroller.current,
            width: 150,
            height: 100,
            padding: 10,
            zoomOptions: { max: 2, min: 0.2 }
        });
        nav.$el.appendTo(navigatorRef.current);
        nav.render();

        if (node?.graph) {
            LOGGER.trace("view.graph defined, loading it: ", node.graph)
            graph.current.fromJSON(node.graph)
        }



        let selection = new jointjs.ui.Selection({ paper: paper.current });

        paper.current.on('blank:pointerdown', scroller.current.startPanning);

        paper.current.on('element:pointerup', function(elementView, evt) {
            if (evt.ctrlKey || evt.metaKey) {
                selection.collection.add(elementView.model);
            }
        });

        selection.on('selection-box:pointerdown', function(elementView, evt) {
            if (evt.ctrlKey || evt.metaKey) {
                selection.collection.remove(elementView.model);
            }
        });


        paper.current.on("element:pointermove", function (elementView, evt, x, y) {
            elementView_pointermove(paper, elementView, evt, x, y, highlighterId, highlighterOptions);
        });

        let wasDragging = false;

        paper.current.on('element:pointerdown', function(elementView, evt) {
            wasDragging = false;
        })

        paper.current.on("element:pointermove", function (elementView, evt, x, y) {
            // Fallback for older browsers
            LOGGER.debug("element:move event.", evt)
            if (typeof evt.buttons === "undefined") {
                if (evt.which === 1) {
                    wasDragging = true;
                }
            } else if (evt.buttons === 1) {
                wasDragging = true;
            }
        })

        paper.current.on('cell:pointerup', function(cellView) {
            // We don't want to transform links.
            if (cellView.model instanceof jointjs.dia.Link) return;

            const elementType = getCustomProp(cellView.model, "type")
            let id = ""
            switch (elementType) {
                case NodeType.Application.description:
                    id = getCustomProp(cellView.model, "applicationId")
                    break
                case NodeType.Capability.description:
                    id = getCustomProp(cellView.model, "capabilityId")
                    break
                default:
                    //ignore
                    break
            }
            if (id) {
                const node = getNodeById(id)
                if (node) {
                    setSoftSelectedNodeById(node.id)
                } else {
                    LOGGER.warn("node not found for id: ", id)
                }
            }

            const freeTransform = new jointjs.ui.FreeTransform({ cellView: cellView, allowRotation: false });
            freeTransform.render();
        });

        paper.current.on("element:pointerup", function (elementView, evt, x, y) {

            if (!wasDragging) {
                // The elementView was not dragged
                LOGGER.debug("elementView was not dragged")
                return;
            }



            const element = elementView.model;
            const elementsUnder = getElementsUnderElement(graph.current, paper.current, element);
            const parent = elementsUnder.findLast((el) => isCapability(el));

            //make sure the elemnt gets this (potentially) new parent
            let parentId = getCustomProp(parent, "capabilityId");
            if (parentId === undefined) {
                parentId = "_capabilities"
            }

            const newParent = getNodeById(parentId)
            const newParentType = newParent?.type
            if (newParentType === "capability" || parentId === "_capabilities") {

                const nodeType = getCustomProp(element, "type");
                let nodeId = null;
                if (nodeType === "application") {
                    nodeId = getCustomProp(element, "applicationId")
                    const applicationNode = getNodeById(nodeId);
                    if (!applicationNode.supportedCapabilityIds) {
                        applicationNode.supportedCapabilityIds = []
                    }
                    if (!applicationNode.supportedCapabilityIds.includes(parentId)) {
                        applicationNode.supportedCapabilityIds.push(parentId)
                    }
                    updateApplicationRectangle(
                        paper.current,
                        getNodeById,
                        applicationNode,
                        element
                    )
                    saveNode(node);
                } else
                if (nodeType === "capability") {
                    nodeId = getCustomProp(element, "capabilityId")
                    const capabilityNode = getNodeById(nodeId);
                    capabilityNode.parentId = parentId;
                    saveNode(capabilityNode);
                    updateCapabilityRectangle(
                        paper.current,
                        getNodeById,
                        capabilityNode,
                        element
                    )
                } else {
                    LOGGER.warn("unknown node type: ", nodeType)
                    return
                }

            }



            if (!isCapability(element)) {
                // The elementView is not a container
                if (parent) {
                    // If an element is embedded into another we make sure
                    // the container is large enough to contain all the embeds
                    resizeCapability(graph.current, parent);
                }
                return;
            }

            // The elementView is a container

            //element.set("z", -1);
            elementView.el.style.opacity = "";
            jointjs.highlighters.mask.remove(elementView, highlighterId);

            if (parent) {
                // The elementView was embedded into another container
                if (elementsUnder.length > 1) {
                    // The container has already children and some of them
                    // are located under the elementView.
                    // Let's make sure none of the children stays under
                    // elementView
                    //layoutEmbeds(graph.current, parent);
                }
                // If an element is embedded into another we make sure
                // the container is large enough to contain all the embeds
                resizeCapability(graph.current, parent);
                return;
            }

            // The elementView has not been embedded
            // We check the elements under the elementView which are not
            // containers and embed them into elementView.
            const newEmbeds = getNewEmbeds(elementsUnder, element);
            if (newEmbeds.length > 0) {
                element.embed(newEmbeds);
                resizeCapability(graph.current, element);
            }
        });

        paper?.current?.model?.on("add change remove", (cell, change) => {
            LOGGER.trace(`graph.change event.`)
            const changesToIgnore = [
                "attrs/body/stroke",
                "attrs/body/stroke-width",
            ]
            if (changesToIgnore.includes(change?.propertyPath)) {
                //ignore it is just to show the hover border, no need to save!
                return
            }
            saveGraphJSON(saveNode, node, graph.current.toJSON())
        })

        /*
        graph.current.on('change:parent', function(element, newParentId) {
            element_changedParent(paper.current, getNodeById, element, newParentId, (node)=>{
                onSaveNode(node)
                //graph itself is saved automatically
            })
        });
        */

        paperRef.current.appendChild(scroller.current.el);
        scroller.current.render().center();

        return () => {
            nav?.$el?.remove()
            //TODO check; this could be the reason why i sometimes get an error when I switch to another diagram after visiting a GridStackComponent4 diagram
            scroller?.current?.remove();
            paper?.current?.remove();
        };
        //disabling the next line because I can't add setStatusMessage to the dependency list, otherwise it will be called in an infinite loop
        // eslint-disable-next-line
    }, [node, snapToGrid]);

    useEffect(() => {
        //alert("node:", node)
        if (node) {
            if (graph?.current) {
                if (node?.graph) {
                    graph.current.fromJSON(node.graph)

                    setTimeout(() => {
                        refreshPaper(paper.current, getNodeById)
                    }, 5)

                } else {
                    LOGGER.warn("graph empty! setting it to: ", EMPTY_GRAPH)
                    node.graph = EMPTY_GRAPH
                    graph.current.fromJSON(node.graph)

                    setTimeout(() => {
                        refreshPaper(paper.current, getNodeById)
                    }, 5)

                }
            }
        } else {
            LOGGER.warn("node is undefined")
        }
        // eslint-disable-next-line
    }, [node]);

    return (
        <div
            ref={ref}
            className={cssStyles.main}
            //TODO onClick={(e)=>{tileSelected(undefined)}}
            data-testid={"grid-stack-component"}
        >
            <div ref={paperRef} className={cssStyles.paperDiv}/>
            <div className={cssStyles.navigationWrapper}>
                <div ref={navigatorRef} className={cssStyles.navigatorDiv}></div>
            </div>
        </div>
    )
}

export default forwardRef(GridStackComponent4);
