import React from 'react'
import cssStyles from './ShareButton.module.css'
import IosShareIcon from '@mui/icons-material/IosShare'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Logger from "../../../../utils/Logger"
import {IconButton} from "@mui/material";

const LOGGER = new Logger('ShareButton.js');



export default function ShareButton({shareOptions}) {

    const [isDownloading, setIsDownloading] = React.useState(false)
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        LOGGER.debug("handleClick")
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        LOGGER.debug("handleClose")
        setAnchorEl(null);
    };



    const handleExport = async (format) => {
        LOGGER.debug("handleExport.format:", format)
        handleClose();
        setIsDownloading(true)
        const option = await shareOptions.find((option)=>option.format===format)
        await option.executeOption()
        setIsDownloading(false)
    };



    return (
        <div className={cssStyles.main}>
            <span className={cssStyles.icon + " " + (isDownloading?cssStyles.downloading:"")}>
                <IconButton className={cssStyles.iconButton} aria-label="refresh" disabled={isDownloading} onClick={handleClick}>
                    <IosShareIcon className={cssStyles.icon + " " + (isDownloading?cssStyles.downloading:"")}/>
                </IconButton>
            </span>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {shareOptions.filter(option=>!option.disabled).map((option) => {
                    return <MenuItem key={option.format} onClick={()=>handleExport(option.format)}>{option.label}</MenuItem>
                })}
            </Menu>
        </div>
    );
};
