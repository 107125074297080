const LOG_LEVEL = 0 //log anything

const onlyTheseLoggers = [
    "Api",
    //"ApplicationComponentPaper",
    //"BusinessProcessDiagram",
    //"BusinessProcessDiagramUtils",
    "BusinessProcessPaper",
    "ConfirmAccountPage",
    //"CustomNode",
    //"DataExchangeDiagram",
    //"DataFlowPaper",
    "EmailPrefiller",
    "EnvironmentInfoProvider",
    //"LoadingOverlay",
    "MarkdownTOC",
    //"ModelContext",
    //"ModelUtils",
    //"PropertyPane",
    "RichTextLabeler",
    "SecurityContext",
    "SubscriptionContext",
    //"SubscriptionTable",
    //"TreeComponent2",
    //"TableField",
    "utils.ts",
]

export default class Logger {

    /*
    Log Levels:
    0=trace
    1=debug
    2=warn
    3=info
    4=error
     */

    constructor(loggerName, logLevel=LOG_LEVEL) {
        this.loggerName = loggerName
        this.logLevel = Math.max(logLevel, LOG_LEVEL) //log anything
    }

    setLogLevel(logLevel) {
        this.logLevel = logLevel
    }

    log(logLevel, message, object) {
        if (logLevel<this.logLevel) {
            return
        }
        if (onlyTheseLoggers.length>0 && !onlyTheseLoggers.includes(this.loggerName)) {
            return
        }
        let consoleFunction
        let logLevelString = "---"
        switch (logLevel) {
            case 0:
                consoleFunction = console.log
                logLevelString = "TRC"
                break
            case 1:
                consoleFunction = console.debug
                logLevelString = "DBG"
                break
            case 2:
                consoleFunction = console.warn
                logLevelString = "WRN"
                break
            case 3:
                consoleFunction = console.info
                logLevelString = "INF"
                break
            case 4:
                consoleFunction = console.error
                logLevelString = "ERR"
                break
            default:
                break
        }
        const d = new Date();
        const zeMessage = d.toISOString() + " - " + logLevelString + " - " + this.loggerName + " - " + message
        if (object) {
            if (typeof object === "object" || Array.isArray(object)) {
                try {
                    consoleFunction(zeMessage, JSON.stringify(object))
                } catch(e) {
                    //to catch exceptions in case of circular references
                    // if such an exception arises --> print normally...
                    consoleFunction(zeMessage, object)
                }
            } else {
                consoleFunction(zeMessage, object)
            }
            return
        }
        consoleFunction(zeMessage)
    }
    trace(message, object) {this.log(0, message, object)}
    debug(message, object) {this.log(1, message, object)}
    warn(message, object) {this.log(2, message, object)}
    info(message, object) {this.log(3, message, object)}
    error(message, object) {this.log(4, message, object)}


}
