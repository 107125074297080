import {TextField} from "@mui/material"

export function MultilineTextField({register, errors, id="description", name="description", label="Description", minRows=5, maxRows=5}) {

    return (
        <TextField
            multiline
            minRows={minRows}
            maxRows={maxRows}
            margin="dense"
            id={id}
            name={name}
            label={label}
            type="text"
            fullWidth
            variant="standard"
            {...register(id)}
            error={!!errors?.[id]}
            helperText={errors?.[id] ? errors?.[id].message : null}
        />
    )
}
