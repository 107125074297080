import {Typography} from "@mui/material";

export function LinkBar(props) {

    const onSupportClick = (event) => {
        event.preventDefault();
        document.querySelector('[data-test="popup-button"]').click();
    }

    return <>
        <Typography variant="h6" noWrap component="div">
            {/* kudos to https://www.unicode.org/cgi-bin/GetUnihanData.pl?codepoint=%E5%B8%AB */}
            <span>
                <a
                    className={"external_link"}
                    href={"https://blog.kenchiku.io"}
                    rel="noreferrer"
                    target={"_blank"}
                >blog</a>
            </span>
        </Typography>
        <Typography variant="h6" noWrap component="div">
            {/* kudos to https://www.unicode.org/cgi-bin/GetUnihanData.pl?codepoint=%E5%B8%AB */}
            <span>|<a className={"external_link"} href={"https://docs.kenchiku.ninja/b446b1a892364fe9b0978adab0399a4f?v=be87c79713c943a2bbffae0ab5afc613"} rel="noreferrer"
                      target={"_blank"}>docs</a></span>
        </Typography>
        <Typography variant="h6" noWrap component="div">
            {/* kudos to https://www.unicode.org/cgi-bin/GetUnihanData.pl?codepoint=%E5%B8%AB */}
            <span>|<a className={"external_link"} onClick={onSupportClick} rel="noreferrer"
                      target={"_blank"}>support</a></span>
        </Typography>
    </>
}
