import cssStyles from "./NodeLabel.module.css";
import * as React from "react";
import {getNodeTypeIcon} from "../../../model/Constants";

export function NodeLabel({ node }) {

    const nodeIcon = getNodeTypeIcon(node.type)
    if (nodeIcon) {
        return <div className={cssStyles.labelDiv}>
            <div className={cssStyles.labelIcon}>{nodeIcon}</div><div className={cssStyles.labelName}>{node.name}</div>
        </div>
    } else {
        return <span className={cssStyles.labelDiv}>
            {node.name}
        </span>
    }
}
