import Logger from "../../../utils/Logger";
import cssStyles from "./DataFlowDiagram.module.css"
import BusinessProcessPaper from "./BusinessProcessDiagram/BusinessProcessPaper";

const LOGGER = new Logger("BusinessProcessDiagram")

export default function BusinessProcessDiagram() {

    LOGGER.trace("BusinessProcessDiagram created")


    const paperWidth = 2800;
    const paperHeight = 1600;

    return <div className={cssStyles.main}>
        <BusinessProcessPaper
            paperWidth={paperWidth}
            paperHeight={paperHeight}
        >
        </BusinessProcessPaper>
    </div>

}
