import {forwardRef, useEffect, useImperativeHandle, useRef} from "react";
import * as jointjs from "@joint/plus";
import Logger from "../../../../utils/Logger";
import cssStyles from "./ApplicationComponentPaper.module.css"

import {
    addToolsToComponentRectangle, addToolsToLink,
    createApplicationComponentRectangle, createDataExchangeLink,
    getApplicationViewAtPosition, revealDataExchange, updateApplicationRectangle, updateDataObjectRectangle
} from "./ApplicationComponentPaperFunctions";
import {
    buildContextMenu,
    EMPTY_GRAPH,
    getCustomProp, hasType,
} from "../utils/JointjsUtils";
import {NodeType} from "../../../../model/Constants";
import {
    getApplicationMasterData, getApplicationSecondaryData,
} from "../utils/DiagramUtils";
import "./jointjs/customShapes"
import {useStatusMessage} from "../../../StatusMessenger/StatusMessageProvider";
import {setDataExchangeNodeOnLink} from "./ViewTransformers";
import {debounce} from "lodash";
import {
    add_element,
    pointermove_cell,
    pointerup_blank,
    pointerdown_cellview, pointerup_element, pointerup_cell, pointerdown_linkview, pointerclick_link_label
} from "./ApplicationComponentPaper_EventHandlers";
import {useModel} from "../../../../model/ModelContext";
import {useSelectedNodes} from "../../../SelectedNodes/SelectedNodesProvider";

const LOGGER = new Logger("ApplicationComponentPaper")

function acceptableType(node) {
    const isAcceptable = [
        NodeType.Application.description,
        NodeType.DataExchange.description,
        NodeType.DataObject.description,
    ].includes(node?.type)
    LOGGER.debug(`acceptableType.node/type/isAcceptable:${node?.type}/${isAcceptable}`)
    return isAcceptable
}



function addLinksForApplication(getNodesByType, getNodeById, removeNodeById, paper, graph, applicationId, selectedArrowType, setStatusMessage) {

    const applicationIdToRectangleIdHash = buildRectangleApplicationHash(graph)
    if (Object.keys(applicationIdToRectangleIdHash).length <= 0) {
        LOGGER.debug("no or just one application on the graph so no links to draw")
        return
    }
    const dataExchange = getNodesByType(NodeType.DataExchange.description).filter(df=>df.sourceApplicationId===applicationId || df.targetApplicationId===applicationId)

    const newDataExchangesOnPaper = []

    dataExchange.forEach((de)=>{
        const sourceRectangleId = applicationIdToRectangleIdHash[de.sourceApplicationId]
        const targetRectangleId = applicationIdToRectangleIdHash[de.targetApplicationId]

        if (sourceRectangleId === targetRectangleId) {
            LOGGER.debug("DataExchange" +
                " with sourceRectangleId === targetRectangleId -> skipping")
            return
        }

        if (!sourceRectangleId || !targetRectangleId) {
            LOGGER.debug("DataExchange's srid or trid is undefined -> don't show dataexchange")
            return
        }
        const link = createDataExchangeLink(
            getNodeById,
            removeNodeById,
            paper,
            sourceRectangleId,
            targetRectangleId,
            selectedArrowType,
            de,
            function(zeLink) {
                setStatusMessage("Deleting data exchange")
                removeNodeById(de.id)
                removeNodeById(de.id)
                zeLink.remove()
            },
            setStatusMessage
        );

        newDataExchangesOnPaper.push(de)
        link.findView(paper).render();
    })
    return {newDataExchangesOnPaper}
}

function updateDataExchangeLink(getNodeById, removeNodeById, paper, link, setStatusMessage) {

    const dataExchangeId = getCustomProp(link, "dataExchangeId")
    if (dataExchangeId) {
        const dataExchange = getNodeById(dataExchangeId)

        if (!dataExchange) {
            LOGGER.debug("dataExchange not found, removing it from the graph")
            link.remove()
            return
        }

        setDataExchangeNodeOnLink(
            getNodeById,
            removeNodeById,
            paper,
            dataExchange,
            link,
            link.getSourceCell(),
            link.getTargetCell(),
            (dataExchangeId)=>{
                setStatusMessage("Deleting data exchange: ", dataExchangeId)

                //link.id is not set yet, because the DataExchange has not yet been created!
                //but we need the dataexchange id anyway...

                //onNodeDeleteHandler(dataExchangeId)
                //removeNode(dataExchangeId)
            },
        )

    } else {
        addToolsToLink(removeNodeById, paper, link, function(zeLink) {
            LOGGER.debug("Removing link from the graph")
            zeLink.remove() //remove the link from the graph
        })
    }

}

function getApplicationIdsOnPaper(graph) {
    let elements = graph.getElements();
    //find all elements with custom type = Application
    let filteredElements = elements.filter(function (element) {
        return hasType(element, NodeType.Application.description)
    });
    //map the elements on their "applicationId" custom property
    let elementIds = filteredElements.map(element => getCustomProp(element, "applicationId"));
    return elementIds;
}

function getDataExchangeIdsOnPaper(graph) {
    let links = graph.getLinks();
    //find all links with custom type = DataExchange
    let dataExchangeLinks = links.filter(function (link) {
        return hasType(link, NodeType.DataExchange.description)
    });
    //map the links on their "dataExchangeId" custom property
    let dataExchangeIds = dataExchangeLinks.map(element => getCustomProp(element, "dataExchangeId"));
    return dataExchangeIds;
    // we used to flatten, but was this really necessary? return dataExchangeIds.flat();
}

function buildRectangleApplicationHash(graph) {
    let idHash = {};
    let elements = graph.getElements();
    let filteredElements = elements.filter(function (element) {
        return hasType(element, NodeType.Application.description)
    });
    filteredElements.forEach((element) => {
        const applicationId = getCustomProp(element, "applicationId")
        if (applicationId) {
            idHash[applicationId] = element.id;
        }
    });
    return idHash;
}

function saveFunctionToDebounce(onSaveGraphJSON, graphJSON) {
    LOGGER.debug("onSaveHandler called")
    if (onSaveGraphJSON) {
        LOGGER.debug("onSave handler defined, graphJSON: ", graphJSON)
        let graphJsonString = JSON.stringify(graphJSON)
        LOGGER.debug("onSave handler defined, string: ", graphJsonString)
        if (typeof onSaveGraphJSON === 'function') {
            onSaveGraphJSON(graphJSON)
        } else {
            LOGGER.debug(`onSaveHandler is not a function, got: ${typeof onSaveGraphJSON}, the object is: `, onSaveGraphJSON)
        }
    } else {
        LOGGER.debug("no onSave handler defined")
    }
}

const debouncedSaveFunction = debounce(saveFunctionToDebounce, 500)

const onSaveHandler = function (onSaveGraphJSON, graphJSON) {
    if (typeof onSaveGraphJSON !== 'function') {
        LOGGER.debug("onSaveHandler is not a function, got: ", typeof onSaveGraphJSON)
        return
    }
    debouncedSaveFunction(onSaveGraphJSON, graphJSON)
}


function refreshPaper(getNodeById, removeNodeById, paper, setStatusMessage) {
    const graph = paper.model
    /*
        get the application and dataobject rectangles and make sure they are (re-)rendered.
        And, add the tools to them as well, as those don't get saved in the JSON
     */
    graph.getCells().forEach((cell) => {
        if (hasType(cell, [NodeType.Application.description, NodeType.DataObject.description, NodeType.DataExchange.description])) {
            //make sure the damn thing is on the paper
            const view = paper.renderView(cell)
            //then insist on updating the view
            view.update()
            //then add the tools if needed
            if (hasType(cell, NodeType.Application.description)) {
                const applicationNode = getNodeById(getCustomProp(cell, "applicationId"))
                updateApplicationRectangle(applicationNode, cell, paper)
                addToolsToComponentRectangle(cell, paper)
            } else if (hasType(cell, NodeType.DataObject.description)) {
                const dataObjectNode = getNodeById(getCustomProp(cell, "dataObjectId"))
                updateDataObjectRectangle(dataObjectNode, cell)
                addToolsToComponentRectangle(cell, paper)
            }
        }
    })
    /*
      go over the links and make sure they are (re-)rendered.
      And, add the tools to them as well, as those don't get saved in the JSON
     */

    graph.getLinks().forEach((link) => {
        updateDataExchangeLink(
            getNodeById,
            removeNodeById,
            paper,
            link,
            setStatusMessage
        )

    })
}

function ApplicationComponentPaper(
    {
        arrowType,
        showMasterData,
        onClose,
        onDraggingActivityChanged,
        isDraggingActive,
        node,
        onSaveGraphJSON,
        automaticallyAddLinks,
        updateApplicationNodeIdsOnPaper,
        updateDataExchangeNodeIdsOnPaper,
        selectedDataExchangeIds,
    }, ref) {

    const {setSoftSelectedNodeById} = useSelectedNodes()
    const {saveNode, getNodeById, removeNodeById, getNodesByType} = useModel()
    const setStatusMessage = useStatusMessage()

    //TODO !!! before unmount make sure to save one last time

    const namespace = jointjs.shapes

    const paperRef = useRef()
    const navigatorRef = useRef(null)


    const graph = useRef(null)
    const paper = useRef(null)
    const scroller = useRef(null)



    let currentHoverOverApplicationCell = null

    useImperativeHandle(ref, ()=>{
        return ({
            getPaper: ()=>paper.current,
            endHover: (hoverClientOffset)=>{
                if (currentHoverOverApplicationCell) {
                    currentHoverOverApplicationCell.attr('body/stroke-width', 0);
                    currentHoverOverApplicationCell = null
                }
            },
            setHoverClientOffset: (hoverClientOffset)=>{
                const paperCoordinates = paper.current.clientToLocalPoint(hoverClientOffset.x, hoverClientOffset.y)
                const targetCell = getApplicationViewAtPosition(graph.current, paperCoordinates.x, paperCoordinates.y)

                //setStatusMessage(`x: ${Math.round(paperCoordinates.x)}, y: ${Math.round(paperCoordinates.y)}, targetCell: ${targetCell?.id}`)
                if (targetCell) {
                    if (targetCell === currentHoverOverApplicationCell) {
                        //we're still hovering over the same cell, nothing to do
                        return
                    } else {
                        //we're hovering over a new cell
                        currentHoverOverApplicationCell?.attr('body/stroke-width', 0);
                        currentHoverOverApplicationCell = targetCell
                        targetCell.attr('body/stroke', "#FC5185");
                        targetCell.attr('body/stroke-width', 5);
                    }
                } else {
                    if (currentHoverOverApplicationCell) {
                        currentHoverOverApplicationCell.attr('body/stroke-width', 0);
                        currentHoverOverApplicationCell = null
                    }
                }

            },
            addNode:(node, x, y)=>{
                if (!graph.current) {
                    return
                }
                LOGGER.debug(`addNode.x/y ${x}/${y}:`, node)
                if (!node) {
                    LOGGER.debug("addNode.node is undefined")
                    return
                }
                if (!acceptableType(node)) {
                    LOGGER.debug("addNode.node is not of acceptable type, got: ", node?.type)
                    return
                }

                const paperCoordinates = paper.current.clientToLocalPoint(x, y)
                LOGGER.debug("Creating new Application Component rectangle")
                //const newRectangle = createApplicationComponentRectangle(graph.current, getNode, searchNodes, node, paperCoordinates.x, paperCoordinates.y, showMasterData)

                const mds = (showMasterData?getApplicationMasterData(getNodeById, node.id):[])
                const sds = (showMasterData?getApplicationSecondaryData(getNodeById, node.id):[])

                const newRectangle = createApplicationComponentRectangle(paper.current,getNodeById,  node, paperCoordinates.x, paperCoordinates.y, showMasterData, mds, sds)
                graph.current.addCell(newRectangle)

                LOGGER.debug("Done Creating new Graph Node: ", graph.current.toJSON())

                addLinksForApplication(getNodesByType, getNodeById, removeNodeById, paper.current, graph.current, node.id, arrowType, setStatusMessage)

                updateApplicationNodeIdsOnPaper(getApplicationIdsOnPaper(graph.current))
                updateDataExchangeNodeIdsOnPaper(getDataExchangeIdsOnPaper(graph.current))
            },
            revealDataExchange: (dataExchangeId, sourceApplicationId, targetApplicationId) => {
                const link = revealDataExchange(
                    getNodeById,
                    removeNodeById,
                    paper.current,
                    setStatusMessage,
                    dataExchangeId,
                    sourceApplicationId,
                    targetApplicationId
                )
                if (link) {
                    link.addTo(graph.current)
                }
            },
            clearPaper:()=>{
                graph.current.clear()
                updateApplicationNodeIdsOnPaper([])
                updateDataExchangeNodeIdsOnPaper([])
            },
            refreshPaper:()=>{
                refreshPaper(getNodeById,removeNodeById, paper.current, setStatusMessage)
            }
        })
    })

    useEffect(()=>{

        if (!paperRef.current) {
            LOGGER.debug("no paperRef.current")
            return
        }

        LOGGER.debug("Creating new Graph instance")
        graph.current = new jointjs.dia.Graph({}, {cellNamespace: namespace})


        //avoid embedded elements from being moved separately from their parent
        //kudos to: https://stackoverflow.com/a/56113520/425677
        const interactive = function(cellView, eventString) {
            const isLocked = getCustomProp(cellView.model, "locked")
            if (isLocked) {
                return {
                    elementMove: false
                };
            }
            if (!cellView.model.isLink()) {
                return {
                    linkMove: false,
                    labelMove: true,
                    arrowheadMove: false,
                    vertexMove: false,
                    vertexAdd: false,
                    vertexRemove: false,
                    useLinkTools: false,
                }
            }
            if (cellView.model.isEmbedded()) {
                return {
                    elementMove: false,
                };
            }

            return true;
        }
        let paperOptions = {
            autoResizePaper: true,
            cellViewNamespace: namespace,
            connectionStrategy: jointjs.connectionStrategies.pinAbsolute,
            cursor: 'grab',
            drawGrid: true,
            foreignObjectRendering: true,
            inertia: true,
            interactive: interactive,
            linkView: jointjs.dia.LinkView.extend({
                pointerclick: function(evt, x, y) {
                    evt.preventDefault()
                    //alert("link clicked: " + JSON.stringify(this.model))
                    setSoftSelectedNodeById(getCustomProp(this.model, "dataExchangeId"))
                }
            }),
            model: graph.current,
            scrollWhileDragging: true,
            snapLabels: true
        };
        paper.current = new jointjs.dia.Paper(paperOptions);

        if (node) {
            if (node.graph) {
                LOGGER.debug("node.graph defined, loading it: ", node.graph)

                graph.current.fromJSON(node.graph)
                LOGGER.debug("Loaded graph: ", graph.current.toJSON())

                setTimeout(() => {

                    refreshPaper(getNodeById, removeNodeById, paper.current, setStatusMessage);

                }, 5)

                //check what the list of dataexchanges and make sure to update the selectedDataExchangeIds
                //const tempApplicationIds = getApplicationIdsOnPaper(graph.current);
                const tempDataExchangeIds = getDataExchangeIdsOnPaper(graph.current);

                const tempNodeIds = graph.current.getElements().filter(function (element) {
                    return hasType(element, NodeType.Application.description)
                }).map(element => getCustomProp(element, "applicationId"));

                updateDataExchangeNodeIdsOnPaper(tempDataExchangeIds)
                updateApplicationNodeIdsOnPaper(tempNodeIds)
            } else {
                LOGGER.warn("graph empty! setting it to: ", EMPTY_GRAPH)
                node.graph = EMPTY_GRAPH
                graph.current.fromJSON(node.graph)

                setTimeout(() => {
                    refreshPaper(getNodeById, removeNodeById, paper.current)
                }, 5)

            }
        }  else {
            LOGGER.warn("view is undefined")
        }

        graph.current.on('add', function(cell, collection, opt) {
            LOGGER.debug(`graph.add event *: ${JSON.stringify(cell)} ${collection} ${opt}`)
            if (cell?.isElement()) {
                add_element(cell, collection, opt, paper.current);
            }
            onSaveHandler(onSaveGraphJSON, graph?.current?.toJSON())
        })


        graph.current.on('remove', ()=>{
            onSaveHandler(onSaveGraphJSON, graph?.current?.toJSON())
        })
        graph.current.on('change', (cell, change)=>{
            LOGGER.debug(`graph.change event: ${JSON.stringify(cell)} ${JSON.stringify(change)}`)
            const changesToIgnore = [
                "attrs/body/stroke",
                "attrs/body/stroke-width",
            ]
            if (changesToIgnore.includes(change?.propertyPath)) {
                //ignore it is just to show the hover border, no need to save!
                return
            }
            onSaveHandler(onSaveGraphJSON, graph?.current?.toJSON())
        })
        graph.current.on('change:position', ()=>onSaveHandler(onSaveGraphJSON, graph?.current?.toJSON()))
        //OK graph.current.on('change:vertices', ()=>onSaveHandler(onSaveGraphJSON, graph?.current?.toJSON()))

        scroller.current = new jointjs.ui.PaperScroller({
            paper: paper.current,
            autoResizePaper: true,
            cursor: 'grab',

        });

        const nav = new jointjs.ui.Navigator({
            paperScroller: scroller.current,
            width: 150,
            height: 100,
            padding: 10,
            zoomOptions: { max: 2, min: 0.2 }
        });
        nav.$el.appendTo(navigatorRef.current);
        nav.render();

        let selection = new jointjs.ui.Selection({
            paper: paper.current,
        })

        const contextMenuTarget = {x:0, y:0}

        LOGGER.debug("attaching context menu to paper")

        //context menu
        paper.current.$el.on("contextmenu", function(event) {
            LOGGER.debug("contextmenu", event)
            const contextMenu = buildContextMenu(contextMenuTarget, [
                {
                    id: "addAllDataExchanges",
                    text: "Add all data exchanges",
                    handler: () => {
                        LOGGER.debug("addAllDataExchanges")

                    },
                }
            ])
            //event.preventDefault()
            //event.stopPropagation()
            contextMenuTarget.x = event.clientX
            contextMenuTarget.y = event.clientY
            contextMenu.render()
        })

        //POINTER DOWN

        paper.current.on('blank:pointerdown', scroller.current.startPanning);

        paper.current.on('blank:pointerup', ()=>{
                pointerup_blank();
        });

        // Initiate selecting when the user grabs the blank area of the paper.
        //paper.current.on('blank:pointerdown', selection.startSelecting)

        // Unselect an element if the CTRL/Meta key is pressed while a selected element is clicked.
        selection.on('selection-box:pointerdown', function(elementView, evt) {
            if (evt.ctrlKey || evt.metaKey) {
                selection.collection.remove(elementView.model);
            }
        })

        paper.current.on('cell:pointerdown', function(cellView, evt, x, y) {
            //evt.preventDefault()
            if (cellView) {
                pointerdown_cellview(cellView, x, y, graph.current);
            }
        })
        paper.current.on('link:pointerdown', function(linkView, evt, x, y) {
            //evt.preventDefault()
            if (linkView) {
                pointerdown_linkview(getNodeById, linkView, setSoftSelectedNodeById);
            }
        })

        paper.current.on('link:pointerclick', function(linkView, evt) {
            if (evt.target.getAttribute('event') === 'link:label') {
                pointerclick_link_label(getNodeById, linkView, setSoftSelectedNodeById);
            }
        });


        //POINTER MOVE

        paper.current.on('cell:pointermove', function(cell, evt, x, y) {
            pointermove_cell(x, y);
        })

        //POINTER UP

        //https://resources.jointjs.com/docs/rappid/v3.5/ui.Selection.html
        // Select an element if CTRL/Meta key is pressed while the element is clicked.
        paper.current.on('element:pointerup', function(cellView, evt) {
            pointerup_element(evt, cellView, selection, graph.current);
        })

        paper.current.on('cell:pointerup', function(cellView, evt, x, y) {
            pointerup_cell(
                setSoftSelectedNodeById,
                getNodeById,
                getNodesByType,
                removeNodeById,
                saveNode,
                x, y,
                cellView, setStatusMessage, paper.current
            );
        })

        paperRef.current.appendChild(scroller.current.el);
        scroller.current.render().center();

        return () => {
            nav?.$el?.remove()
            scroller?.current?.remove()
            paper?.current?.remove()
        };
        //disabling the next line because I can't add setStatusMessage to the dependency list, otherwise it will be called in an infinite loop
        // eslint-disable-next-line
    }, [node])



    return (
        <div className={cssStyles.mainDiv}>
            <div ref={paperRef} className={cssStyles.paperDiv}/>
            <div className={cssStyles.navigationWrapper}>
                <div ref={navigatorRef} className={cssStyles.navigatorDiv}></div>
            </div>
        </div>
    )
}

export default forwardRef(ApplicationComponentPaper)
