import {getCustomProp, setCustomProp} from "../utils/JointjsUtils";
import {NodeType} from "../../../../model/Constants";
import {addToolsToLink} from "./ApplicationComponentPaperFunctions";

export function fromLinkToDataExchangeNode(link) {
    const sourceElementId = link.get("source").id
    const targetElementId = link.get("target").id
    const sourceElement = link.graph.getCell(sourceElementId)
    const targetElement = link.graph.getCell(targetElementId)
    return {sourceElement, targetElement}
}

export function fromElementToApplicationNode(element) {
    const applicationNode = element.graph.getCell(getCustomProp(element, "applicationId"))
    return applicationNode
}

export function setDataExchangeNodeOnLink(
    getNodeById,
    removeNodeById,
    paper,
    dataExchangeNode,
    link,
    sourceElement,
    targetElement,
) {
    const graph = paper.model

    const labelStrings = (dataExchangeNode?.dataObjectIds?.map((doId, index)=>(getNodeById(doId)?.name||"-")) || ["-"])
    const multilineText = labelStrings.join("\n")
    //clear all labels
    link.labels([])
    link.appendLabel({
        attrs: {
            rect: { fill: 'white' },
            text: {
                text: multilineText
            }
        },
        position: {
            offset: {
                y: -10
            }
        }
    });

    if (sourceElement) {
        if (typeof sourceElement === "string") {
            link.source(graph.getCell(sourceElement))
        } else {
            link.source(sourceElement)
        }
    }
    if (targetElement) {
        if (typeof targetElement === "string") {
            link.target(graph.getCell(targetElement))
        } else {
            link.target(targetElement)
        }
    }

    setCustomProp(link, "dataExchangeId", dataExchangeNode?.id)
    setCustomProp(link, "name", dataExchangeNode?.name)
    setCustomProp(link, "description", dataExchangeNode?.description)
    setCustomProp(link, "dataObjectIds", dataExchangeNode?.dataObjectIds)
    setCustomProp(link, "sourceApplicationId", dataExchangeNode?.sourceApplicationId)
    setCustomProp(link, "targetApplicationId", dataExchangeNode?.targetApplicationId)
    setCustomProp(link, "type", NodeType.DataExchange.description)

    //link.attr("label/text", dataExchangeNode?.name)

    addToolsToLink(removeNodeById, paper, link, function (zeLink) {

        const dataExchangeId = getCustomProp(zeLink, "dataExchangeId")

        removeNodeById(dataExchangeId)

        zeLink.remove()
    })

}

export function setApplicationNodeToElement(
    applicationNode,
    element,
) {

    element.attr("label/text", applicationNode?.name)
    setCustomProp(element, "applicationId", applicationNode?.id)
    setCustomProp(element, "type", NodeType.Application.description)

}

export function setDataObjectNodeToElement(
    graph,
    dataObjectNode,
    parentApplicationRectangle,
    element,
    role
) {

    element.attr("label/text", dataObjectNode?.name)
    setCustomProp(element, "dataObjectId", dataObjectNode?.id)
    setCustomProp(element, "type", NodeType.DataObject.description)
    setCustomProp(element, "applicationComponentId", parentApplicationRectangle.id)
    setCustomProp(element, "applicationId", getCustomProp(parentApplicationRectangle, "applicationId"))
    setCustomProp(element, "locked", true)
    setCustomProp(element, "role", role) //"master" or "slave"
}
