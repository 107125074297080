import * as React from "react";
import Logger from "../../../utils/Logger";
import cssStyles from "./DataObjectDiagram.module.css"
import {useState} from "react";
import {Tree, TreeNode} from "react-organizational-chart";
import {Checkbox, FormControlLabel} from "@mui/material";
import {NodeType} from "../../../model/Constants";
import {useSelectedNodes} from "../../SelectedNodes/SelectedNodesProvider";
import {useModel} from "../../../model/ModelContext";
import {Card} from "./Card";
import {NodeLabel} from "./NodeLabel";

const LOGGER = new Logger("DataObjectDiagram")

function buildTree(searchNodes, node, level, showApplications) {

    if (level > 20) {
        LOGGER.error("buildTree: too many levels!")
        return null
    }

    let sourceApplicationChildren = []
    let targetApplicationChildren = []
    if (node.type === NodeType.DataExchange.description) {
        sourceApplicationChildren = [{
            id: "label="+node.id,
            name: "source",
            type: "_label",
            children:
                searchNodes((n) => n.type === NodeType.Application.description && n.id === node.sourceApplicationId)
                .map(c => buildTree( searchNodes,c, level + 1, showApplications)).filter(n=>n!==null)
        }]
        targetApplicationChildren = [{
            id: "label=" + node.id,
            name: "target",
            type: "_label",
            children: searchNodes((n) => n.type === NodeType.Application.description && n.id === node.targetApplicationId)
                .map(c => buildTree(searchNodes,c, level + 1, showApplications)).filter(n=>n!==null)
        }]
    }

    let dataObjectChildren = []
    let dataExchangeChildren = []
    let masterApps = []
    if (node.type === NodeType.DataObject.description) {
        if (showApplications) {
            const masterNodes = searchNodes((n) => n.type === NodeType.Application.description && n.masterDataObjectIds?.includes(node.id))
                .map(c => buildTree( searchNodes,c, level + 1, showApplications)).filter(n=>n!==null)
            if (masterNodes?.length>0) {
                masterApps = [{
                    id: "label=" + node.id,
                    name: (masterNodes?.length===1 ? "master":"masters"),
                    type: "_label",
                    children: masterNodes
                }]
            }
        }

        dataObjectChildren = searchNodes((n) => n.type === NodeType.DataObject.description && n?.parentId === node.id)
            .map(c => buildTree( searchNodes,c, level + 1, showApplications)).filter(n=>n!==null)

        if (!dataObjectChildren?.length) {
            dataObjectChildren = []
        }

        const exchangeNodes = searchNodes((n) => n.type === NodeType.DataExchange.description && n?.dataObjectIds?.includes(node.id))
        if (exchangeNodes?.length>0) {
            const exchangeTreeNodes = exchangeNodes.map(c => buildTree( searchNodes, c, level + 1, showApplications)).filter(n=>n!==null)
            dataExchangeChildren = [{
                id: "label=" + node.id,
                name: (exchangeNodes?.length === 1 ? "exchange" : "exchanges"),
                type: "_label",
                children: exchangeTreeNodes
            }]
        }
    }

    let appChildren =  []

    if (showApplications) {
        appChildren = searchNodes((n)=>n.type===NodeType.Application.description && n.supportedCapabilityIds?.includes(node.id))
            .map(c=>buildTree(searchNodes, c, level+1, showApplications)).filter(n=>n!==null)
    }

    let children = [...masterApps, ...dataExchangeChildren, ...sourceApplicationChildren, ...targetApplicationChildren, ...appChildren, ...dataObjectChildren]

    if (node.dataOwnerId) {
        let actorChild = searchNodes((n)=>n.type===NodeType.Actor.description && n.id===node.dataOwnerId)
        if (actorChild?.length>0) {
            children.push(actorChild[0])
        }
    }


    return {
        id: node.id,
        name: <NodeLabel node={node}/>,
        type: node.type,
        children: children,
        level: level,
    }
}

function buildTreeNode(node, handleNodeClick, selectedNodes) {
    if (!node.id) {
        return <></>
    }
    return <TreeNode label={<Card selectedNodes={selectedNodes} node={node} level={node?.level} handleNodeClick={()=>handleNodeClick(node.id)}/>} >
        {node?.children?.map((c)=>buildTreeNode(c, handleNodeClick, selectedNodes))}
    </TreeNode>
}

export default function DataObjectDiagram({node}) {

    const {searchNodes} = useModel()
    const {setSoftSelectedNodeById, selectedNodes} = useSelectedNodes()

    const [showApplications, setShowApplications] = useState(true)

    const tree = buildTree(searchNodes, node, 0, showApplications)

    function handleNodeClick(nodeId) {
        LOGGER.debug("handleNodeClick:", nodeId)
        setSoftSelectedNodeById(nodeId)
    }

    const labelAppObjects = { label:"Show Applications" };
    const nodeId = node.id
    const nodeName = node.name
    return (
        <div key={'cd-'+nodeId} className={[cssStyles.main, ].join(" ")}>
            {nodeName}
            <hr/>
            <div key={"data-object-diagram.filterpane"} className={cssStyles.filterPane}>
                <FormControlLabel
                    {...labelAppObjects}
                    control={<Checkbox defaultChecked />}
                    value={showApplications}
                    onChange={(event)=>{setShowApplications(oldValue=>!oldValue)}}
                />
            </div>
            <Tree label={<div key={"data-object-diagram.tree.label"}>Data Object</div>}>
                {buildTreeNode(tree, handleNodeClick, selectedNodes)}
            </Tree>
        </div>);
}
