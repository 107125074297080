import {getCustomProp, getRootAncestor, hasType, isEmbedded, linkLength, showDialog} from "../utils/JointjsUtils";
import {NodeType} from "../../../../model/Constants";
import {
    addToolsToComponentRectangle, createDataExchange, createLink, getApplicationViewAtPosition,
    getDataObjectViewAtPosition, getMatchingDataExchanges, revealDataExchange
} from "./ApplicationComponentPaperFunctions";
import * as jointjs from "@joint/plus";
import Logger from "../../../../utils/Logger";
import {DATA_EXCHANGE_SCHEMA} from "../../editors/DataExchangeEditor";
import {setDataExchangeNodeOnLink} from "./ViewTransformers";

const LOGGER = new Logger("ApplicationComponentPaper_EventHandlers")

let linkWrapper = {link: new jointjs.shapes.standard.Link()}

export function add_element(cell, collection, opt, paper) {
    const graph = paper.model
    LOGGER.debug(`graph.add event element: ${JSON.stringify(cell)} ${collection} ${opt}`)
    if (graph.getCell(cell.id)) {
        LOGGER.debug(`graph.add event element on GRAPH: ${JSON.stringify(cell)} ${collection} ${opt}`)
        paper.renderView(cell)
        if (cell.findView(paper)) {
            LOGGER.debug(`graph.add event element on PAPER: ${JSON.stringify(cell)} ${collection} ${opt}`)
            if (hasType(cell, NodeType.Application.description)) {
                LOGGER.debug(`graph.add event element on PAPER: ${JSON.stringify(cell)} ${collection} ${opt}`)
                addToolsToComponentRectangle(cell, paper)
            }
        }
    }
}

export function pointerdown_linkview(getNodeById, linkView, setSoftSelectedNodeById) {
    if (linkView.model.isLink() && hasType(linkView.model, NodeType.DataExchange.description)) {
        setSoftSelectedNodeById(getCustomProp(linkView.model, "dataExchangeId"))
    }
}

export function pointerclick_link_label(getNodeById, linkView, setSoftSelectedNodeById) {
    if (linkView.model.isLink() && hasType(linkView.model, NodeType.DataExchange.description)) {
        setSoftSelectedNodeById(getNodeById(linkView.model, "dataExchangeId"))
    }
}

export function pointerdown_cellview(cellView, x, y, graph) {
    if (hasType(cellView.model, NodeType.DataObject.description)) {
        linkWrapper.link = new jointjs.shapes.standard.Link()
        //only showing the arrow when the user is really dragging
        //so we create it hidden and as the user moves away (+5px) from the starting point, we show it
        linkWrapper.link.attr({
            line: {
                display: 'none'
            }
        });
        linkWrapper.link.source({x: x, y: y});
        linkWrapper.link.target({x: x, y: y});
        linkWrapper.link.addTo(graph);
    }
}

export function pointermove_cell(x, y) {
    if (linkWrapper.link && linkWrapper.link.isLink()) {
        //only showing the arrow when the user is really dragging
        //so we create it hidden and as the user moves away (+5px) from the starting point, we show it
        if (linkLength(linkWrapper.link) < 5) {
            linkWrapper.link.attr({
                line: {
                    display: 'none'
                }
            });
        } else {
            linkWrapper.link.attr({
                line: {
                    display: ''
                }
            });
        }
        linkWrapper.link.target({x: x, y: y});
    }
}

export function pointerup_blank() {
    //the user has released the pointer directly above the paper, and not above an object (like an application rectangle for instance)
    if (linkWrapper.link) {
        //if the user was drawing an arrow, they released it above "nothing", so there's nothing to connect
        //just remove the temporary link
        linkWrapper.link.remove()
        linkWrapper.link = undefined
    }
}

export function pointerup_element(evt, cellView, selection, graph) {
    if (evt.ctrlKey || evt.metaKey) {
        if (isEmbedded(cellView.model)) {
            // Prevent further actions on this event
            evt.stopPropagation();
            selection.collection.add(getRootAncestor(cellView.model, graph));
            return
        } else {
            selection.collection.add(cellView.model);
        }
    }
}

function createDataExchangeAndLink(getNodeById, removeNodeById, onSaveNode, dataExchange, paper, sourceApplicationCell, targetCell, setStatusMessage) {
    onSaveNode(dataExchange)
    const link = createLink(paper.model, sourceApplicationCell.id, targetCell.id, "manhattan", dataExchange.dataObjectIds.map(doId => getNodeById(doId).name))
    setDataExchangeNodeOnLink(
        getNodeById,
        removeNodeById,
        paper,
        dataExchange,
        link,
        sourceApplicationCell,
        targetCell,
        (dataExchangeId) => {
            setStatusMessage("Deleting data exchange: ", dataExchangeId)
            //onNodeDeleteHandler(dataExchangeId)
        }
    )
    return link;
}

function reuseAndRevealDataExchangeMatchAndLink(
    getNodeById, removeNodeById, saveNode,
    dataExchangeMatch, paper, setStatusMessage, sourceApplicationCell, targetCell, graph, sourceDataObjectId) {
    if (dataExchangeMatch.isExactMatch) {
        const dataExchange = dataExchangeMatch.dataExchange
        //nothing to do, the data exchange is already there
        const link = revealDataExchange(
            getNodeById,
            removeNodeById,
            paper,
            setStatusMessage,
            dataExchange.id,
            sourceApplicationCell.id,
            targetCell.id
        )
        if (link) {
            link.addTo(graph)
        }
        return
    }
    //add the dataobject to the existing data exchange
    const existingDataExchange = dataExchangeMatch.dataExchange
    if (!existingDataExchange.dataObjectIds) {
        existingDataExchange.dataObjectIds = []
    }
    if (!existingDataExchange.dataObjectIds?.includes(sourceDataObjectId)) {
        existingDataExchange.dataObjectIds.push(sourceDataObjectId)
    }
    saveNode(existingDataExchange)
    const link = revealDataExchange(
        getNodeById,
        removeNodeById,
        paper,
        setStatusMessage,
        existingDataExchange.id,
        sourceApplicationCell.id,
        targetCell.id
    )
    if (link) {
        link.addTo(graph)
    }
}

export function pointerup_cell(setSoftSelectedNodeById, getNodeById, getNodesByType, removeNodeById, saveNode, x, y, cellView, setStatusMessage, paper) {

    const graph = paper.model

    let cellsAtPoint = graph.findModelsFromPoint({x, y});

    if (cellsAtPoint.length === 1) {
        // Do something with the cells
        let cell = cellsAtPoint[0]; // This gets the first cell at that point
        const type = getCustomProp(cell, "type")
        if (type === NodeType.Application.description) {
            const applicationId = getCustomProp(cell, "applicationId");
            LOGGER.debug("applicationId: ", applicationId)
            setSoftSelectedNodeById(applicationId)
            // return -> no need to exit here, we still need to check if there's a link being made...
        }
    } else {
        console.log("No cell found at the specified point");
    }


    if (!linkWrapper.link) {
        return
    } else {
        const graph = paper.model
        let ll = linkLength(linkWrapper.link)
        if (ll < 20) {
            linkWrapper.link.remove()
            linkWrapper.link = undefined
            const dataObjectCell = getDataObjectViewAtPosition(graph, x, y)
            if (dataObjectCell) {
                LOGGER.debug("dataObjectCell: ", dataObjectCell)
                let dataObjectId = getCustomProp(dataObjectCell, "dataObjectId");
                LOGGER.debug("dataObjectCell.dataObjectId: ", dataObjectId)
                setSoftSelectedNodeById(dataObjectId)
            }
            return
        }
        if (cellView) {
            //cellView is the data object!

            const sourceDataObjectCell = cellView?.model
            const sourceDataObjectId = getCustomProp(sourceDataObjectCell, "dataObjectId")//sourceDataObjectCell?.attributes?.data?.id
            const sourceDataObject = getNodeById(sourceDataObjectId)

            const sourceApplicationCell = sourceDataObjectCell?.getParentCell()
            const sourceApplicationId = getCustomProp(sourceDataObjectCell, "applicationId") //cellView.model?.attributes?.data?.applicationId
            const sourceApplication = getNodeById(sourceApplicationId)

            const targetCell = getApplicationViewAtPosition(graph, x, y)
            const targetApplicationId = getCustomProp(targetCell, "applicationId") //it's a cell not a cellView, so no need to go through the ".model" attribute
            const targetApplication = getNodeById(targetApplicationId)

            if (!sourceApplication) {
                linkWrapper.link.remove()
                linkWrapper.link = undefined
                LOGGER.debug("no source application")
                setStatusMessage("No source application")
                return
            }
            if (!sourceApplicationCell) {
                linkWrapper.link.remove()
                linkWrapper.link = undefined
                LOGGER.debug("no source application")
                setStatusMessage("No source application found")
                return
            }
            if (!targetApplication) {
                linkWrapper.link.remove()
                linkWrapper.link = undefined
                LOGGER.debug("no target application")
                setStatusMessage("No target application")
                return
            }
            if (sourceApplicationId === targetApplicationId) {
                linkWrapper.link.remove()
                linkWrapper.link = undefined
                LOGGER.debug("source and target application ids are the same, not allowed, skipping")
                setStatusMessage("Source and target applications are the same, this is not allowed")
                return
            }
            if (!sourceDataObject) {
                linkWrapper.link.remove()
                linkWrapper.link = undefined
                LOGGER.debug("no source data object")
                setStatusMessage("No source data object")
                return
            }

            if (hasType(cellView.model, NodeType.DataObject.description)) {
                if (linkWrapper.link && linkWrapper.link.isLink() && linkWrapper.link.get('source').id === undefined) {

                    const dataExchange = createDataExchange(
                        getNodeById(sourceDataObjectId),
                        sourceApplication,
                        targetApplication,
                    )

                    const existingDataExchangesMatches = getMatchingDataExchanges(
                        getNodesByType,
                        sourceApplicationId,
                        targetApplicationId,
                        sourceDataObjectId
                    )



                    if (existingDataExchangesMatches.length === 0) {
                        //just create it , there's nothing to ask the user
                        // it's the first time we create a data exchange between that source and target
                        const link = createDataExchangeAndLink(getNodeById, removeNodeById, saveNode, dataExchange, paper, sourceApplicationCell, targetCell, setStatusMessage);
                        link.addTo(paper.model)
                    } else if (existingDataExchangesMatches.length === 1) {
                        //ask the user if we need to create a new one, add it to the existing, or do nothing
                        showDialog({
                            text: "A data exchange with the same source data object, source application and target application already exists. Please select which to reuse. Or press 'create new' to create a new one.",
                            buttons: [{
                                id: "reuse",
                                text: "Reuse",
                                handler: () => {

                                    linkWrapper?.link?.remove()
                                    const dataExchangeMatch = existingDataExchangesMatches[0]
                                    reuseAndRevealDataExchangeMatchAndLink(getNodeById, removeNodeById, saveNode, dataExchangeMatch, paper, setStatusMessage, sourceApplicationCell, targetCell, graph, sourceDataObjectId);
                                }
                            }, {
                                id: "create",
                                text: "Create new",
                                handler: () => {
                                    linkWrapper?.link?.remove()
                                    const link = createDataExchangeAndLink(getNodeById, removeNodeById, saveNode, dataExchange, paper, sourceApplicationCell, targetCell, setStatusMessage);
                                    link.addTo(paper.model)
                                }
                            }, {
                                id: "no",
                                text: "Cancel",
                                handler: () => {
                                    linkWrapper?.link?.remove()
                                },
                                sameAsClose: true,
                            }],
                        });
                        return
                    } else {
                        //ask the user if we need to create a new one, add it to an existing (and if so to which one), or do nothing
                        showDialog({
                            text: "A data exchange with the same source data object, source application and target application already exists. Please select which to reuse. Or press 'create new' to create a new one.",
                            buttons: [{
                                id: "reuse",
                                text: "Reuse",
                                handler: (selectedDataExchangeIds) => {
                                    linkWrapper?.link?.remove()
                                    if (selectedDataExchangeIds.length === 1) {
                                        const selectedDataExchangeId = selectedDataExchangeIds[0]
                                        const selectedDataExchangeMatch = existingDataExchangesMatches.find(dfm => dfm.dataExchange.id === selectedDataExchangeId)
                                        reuseAndRevealDataExchangeMatchAndLink(getNodeById, removeNodeById, saveNode, selectedDataExchangeMatch, paper, setStatusMessage, sourceApplicationCell, targetCell, graph, sourceDataObjectId);
                                    }
                                }
                            }, {
                                id: "create",
                                text: "Create new",
                                handler: () => {
                                    linkWrapper?.link?.remove()
                                    const link = createDataExchangeAndLink(getNodeById, removeNodeById, saveNode, dataExchange, paper, sourceApplicationCell, targetCell, setStatusMessage);
                                    link.addTo(paper.model)
                                }
                            }, {
                                id: "no",
                                text: "Cancel",
                                handler: () => {
                                    linkWrapper?.link?.remove()
                                },
                                sameAsClose: true,
                            }],
                            selectOptions: existingDataExchangesMatches.map(dfm => ({
                                value: dfm?.dataExchange?.id,
                                text: dfm?.dataExchange?.name,
                            })),
                        });
                        return
                    }


                    try {
                        DATA_EXCHANGE_SCHEMA.validate(dataExchange).then((dataExchange) => {

                            const realLink = new jointjs.shapes.standard.Link()
                            linkWrapper.link.addTo(graph)
                            setDataExchangeNodeOnLink(
                                getNodeById,
                                removeNodeById,
                                paper,
                                dataExchange,
                                realLink,
                                sourceApplicationCell,
                                targetCell,
                                (dataExchangeId) => {
                                    setStatusMessage("Deleting data exchange: ", dataExchangeId)

                                    //linkWrapper.link.id is not set yet, because the DataExchange has not yet been created!
                                    //but we need the dataexchange id anyway...

                                    //onNodeDeleteHandler(dataExchangeId)
                                }
                            )

                            //add custom data & tools
                            linkWrapper.link.remove()
                            linkWrapper.link = undefined

                            realLink.addTo(graph)

                            //onNewDataExchangeHandler(sourceDataObjectId, sourceApplicationId, targetApplicationId, realLink)
                        })
                    } catch (err) {
                        LOGGER.debug("Error validating data exchange: ", err)
                        linkWrapper.link?.remove()
                        linkWrapper.link = undefined
                        setStatusMessage("Error creating DataExchange.")
                    }

                    return
                }
            }
        }
        //in any other case, remove the link
        linkWrapper.link.remove()
        linkWrapper.link = undefined
    }
}
