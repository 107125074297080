import {createContext, useContext, useEffect, useState} from "react";
import Logger from "../utils/Logger";
import {useSecurityContext} from "../security/SecurityContext";
import {useWorkspaceContext} from "../security/WorkspaceContext";
import {addAuthorizationHeader} from "../utils/Api";
import {getNodeCollectionName, getNodeTypeFields, getNodeTypeRootFolderId, NodeFolderRootIds} from "./Constants";

const LOGGER = new Logger("ModelContext")

const ModelContext = createContext();

/*
 * This is the context provider for the model. It is responsible for loading the model data from the server and
 * making it available to the rest of the application.
 *
 * TODO: consider implementting debounce on the reload function
 */


export const useModel = () => {
    return useContext(ModelContext);
}

function getParentIdSafely(d, typeRootId) {
    let parentId = (d.parentId ? d.parentId : typeRootId)
    if (parentId === d.id) {
        LOGGER.warn("Careful, parentId === d.id; d=", d)
        parentId = typeRootId
    }
    return parentId
}

async function fetchNodes(workspaceId, user) {

    if (!workspaceId) {
        LOGGER.debug("missing workspaceId")
        return []
    }

    if (!user) {
        LOGGER.debug("missing user")
        return []
    }


    return fetch(
        "/.netlify/functions/manage-model-objects", workspaceId && user && {
        // only attach headers if user is logged in, but still make the request regardless
        headers: addAuthorizationHeader({}, workspaceId, user)
    }).then(res => {
        return res.json()
    }).then(data => {
        LOGGER.debug("folders data: ", data)
        const newNodes = []
        data.forEach((d) => {
            //LOGGER.debug("folders:", d)
            const nodeTypeString = d.type
            const rootIdString = getNodeTypeRootFolderId(nodeTypeString)

            let parentId = getParentIdSafely(d, rootIdString)
            let fields = getNodeTypeFields(nodeTypeString)

            const newNode = {
                type: nodeTypeString
            }
            fields.forEach((f) => {
                newNode[f] = d[f]
            })
            newNode.parentId = parentId
            newNode.owner_id = d?.owner_id
            newNodes.push(newNode)
        })

        let viewsFolder = {
            id: NodeFolderRootIds.ViewRootId.description,
            name: "Views",
            type: "folder",
        };
        let talsFolder = {
            id: NodeFolderRootIds.TalRootId.description,
            name: "TALs",
            type: "folder",
        };
        let scenariosFolder = {
            id: NodeFolderRootIds.ScenarioRootId.description,
            name: "Scenarios",
            type: "folder",
        };
        let domainsFolder = {
            id: NodeFolderRootIds.DomainRootId.description,
            name: "Domains",
            type: "folder",
        };
        let actorActivitiesFolder = {
            id: NodeFolderRootIds.ActorActivityRootId.description,
            name: "Actor Activities",
            type: "folder",
        };
        let actorsFolder = {
            id: NodeFolderRootIds.ActorRootId.description,
            name: "Actors",
            type: "folder",
        };
        let dataExchangesFolder = {
            id: NodeFolderRootIds.DataExchangeRootId.description,
            name: "Data Exchanges",
            type: "folder"
        }
        let dataFlowsFolder = {
            id: NodeFolderRootIds.DataFlowRootId.description,
            name: "Data Flows",
            type: "folder",
        };
        let dataObjectsFolder = {
            id: NodeFolderRootIds.DataObjectRootId.description,
            name: "Data Objects",
            type: "folder",
        };
        let capabilitiesFolder = {
            id: NodeFolderRootIds.CapabilityRootId.description,
            name: "Capabilities",
            type: "folder",
        }
        let businessProcessesFolder = {
            id: NodeFolderRootIds.BusinessProcessRootId.description,
            name: "Business Processes",
            type: "folder",
        }
        let functionalitiesFolder = {
            id: NodeFolderRootIds.FunctionalityRootId.description,
            name: "Functionalities",
            type: "folder",
        }
        let applicationsFolder = {
            id: NodeFolderRootIds.ApplicationRootId.description,
            name: "Applications",
            type: "folder",
        }
        let maturityModelAnalysesFolder = {
            id: NodeFolderRootIds.MaturityModelAnalysisRootId.description,
            name: "Maturity Model Analyses",
            type: "folder",
        }
        let middlewaresFolder = {
            id: NodeFolderRootIds.MiddlewareRootId.description,
            name: "Middlewares",
            type: "folder",
        }
        let architectureBuildingBlockFolder = {
            id: NodeFolderRootIds.ArchitectureBuildingBlockRootId.description,
            name: "Architecture Building Blocks",
            type: "folder",
        }
        let referenceArchitectureFolder = {
            id: NodeFolderRootIds.ReferenceArchitectureRootId.description,
            name: "Reference Architecture",
            type: "folder",
        }
        let snapshotsFolder = {
            id: NodeFolderRootIds.SnapshotRootId.description,
            name: "Snapshots",
            type: "folder",
        };
        let principlesFolder = {
            id: NodeFolderRootIds.PrincipleRootId.description,
            name: "Principles",
            type: "folder",
        }
        let designDecisionsFolder = {
            id: NodeFolderRootIds.DesignDecisionRootId.description,
            name: "Design Decisions",
            type: "folder",
        }

        newNodes.push(viewsFolder)
        newNodes.push(talsFolder)
        newNodes.push(scenariosFolder)
        newNodes.push(referenceArchitectureFolder)
        newNodes.push(architectureBuildingBlockFolder)
        newNodes.push(domainsFolder)
        newNodes.push(actorActivitiesFolder)
        newNodes.push(actorsFolder)
        newNodes.push(capabilitiesFolder)
        newNodes.push(businessProcessesFolder)
        newNodes.push(functionalitiesFolder)
        newNodes.push(applicationsFolder)
        newNodes.push(dataFlowsFolder)
        newNodes.push(dataObjectsFolder)
        newNodes.push(dataExchangesFolder)
        newNodes.push(snapshotsFolder)
        newNodes.push(middlewaresFolder)
        newNodes.push(maturityModelAnalysesFolder)
        newNodes.push(principlesFolder)
        newNodes.push(designDecisionsFolder)


        return newNodes
    }).catch((e) => {
        LOGGER.error("error fetching nodes':", e)
    })
}

async function saveOneNode(workspaceId, user, node, onStartSave, onStopSave, onError) {

    //TODO save the node
    if (0 === node?.id?.indexOf("_")) {
        LOGGER.warn("someone tried saving an object with internal id (i.e., starting with '_'. refused.")
        return
    }

    if (node && node?.id === node?.parentId) {
        LOGGER.warn("someone tried to save a object with its ID as its parentId -> circular. not good. refusing to save...")
        node.parentId = getParentIdSafely(node, getNodeCollectionName(node.type))
        LOGGER.info("Corrected parentId before saving node. node.parentId: ", node.parentId)
    }

    if (onStartSave) {
        onStartSave(node)
    }

    LOGGER.debug("saveObject.node:", node)
    LOGGER.trace("", node)

    let endpoint = "/.netlify/functions/manage-model-objects"
    LOGGER.debug("saveObject.node_type:", node.type)
    LOGGER.debug("saveObject.endpoint:", endpoint)

    let bodyString = ""
    try {
        LOGGER.debug(`stringifying object`)
        bodyString = JSON.stringify(node)
        LOGGER.debug(`stringified object: ${bodyString}.`)
    } catch (e) {
        LOGGER.error("Error stringifying object: ", node)
        LOGGER.error("Error stringifying object: ", e)
        onError("An error occurred while saving...", e)
    }

    const savedNode = await fetch(endpoint,{
        headers: addAuthorizationHeader({
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }, workspaceId, user),
        method: "POST",
        body: bodyString
    }).then(res => {
        LOGGER.debug("Got response")
        if (!res.ok) {
            res.json().then((jsonData) => {
                onError(`${jsonData}`)
            })
            return null
        } else {
            return res.json()
        }
    }).then(obj=> {
        LOGGER.debug("received post result")
        LOGGER.trace("obj: ", obj)
        return obj
    }).catch(e => {
        LOGGER.error("Error POSTing object: ", e)
        if (onError) {
            onError("An error occurred while saving...", e)
            return
        }
    })
    if (onStopSave) {
        //todo not very happy with the way we handle "owner_id" here... but it works for now
        onStopSave({...savedNode, owner_id: workspaceId})
    }
    return {...savedNode, owner_id: workspaceId}

}

//this is needed, because if I use the useState directly in the ModelContextProvider, the state is not updated in the ModelContextProvider
//go figure...
let nodes = []

export function ModelContextProvider({children}) {

    //const [nodes, setNodes] = useState([]);
    const [loading, setLoading] = useState(false);
    const [removing, setRemoving] = useState(false);
    const [updating, setUpdating] = useState(false);
    const [error, setError] = useState(null);
    const [updatedNode, setUpdatedNode] = useState([])

    const {user} = useSecurityContext()
    const {selectedWorkspace} = useWorkspaceContext()

    async function reload() {
        LOGGER.debug("reloading nodes")
        if (loading) {
            LOGGER.debug("already loading nodes, skipping this round")
            return
        }
        if (!user) {
            LOGGER.debug("no user")
            return
        } else if (!selectedWorkspace) {
            LOGGER.debug("no workspace")
            return
        }
        try {
            LOGGER.debug("calling fetchNodes")
            setLoading(true)
            await fetchNodes(selectedWorkspace, user).then((fetchedNodes) => {
                LOGGER.debug("fetchedNodes:", fetchedNodes)
                nodes = (fetchedNodes)
            }).catch((e) => {
                LOGGER.error("error loading data: ", e)
                setError("Error fetching data")
            }).finally(() => {
                setLoading(false)
            })
        } catch (e) {
            LOGGER.error("error loading data: ", e)
            setError("Error loading data")
        }
        LOGGER.debug("done reload")
    }


    async function saveNode(node, source = "unknown") {
        LOGGER.debug("saving node: ", node)
        LOGGER.debug("calling ModelContext.saveOneNode")
        setUpdating(true)
        const savedNode = await saveOneNode(selectedWorkspace, user, node,
            (savedNode) => {
                LOGGER.debug("Saving node: ", savedNode)
                //setUpdating({source: source, nodeBeingUpdated: node})
            },
            (savedNode) => {
                LOGGER.debug("node saved: ", savedNode)
                let updated = false

                LOGGER.trace("going through nodes and replacing the saved node with the updated one if it already existed.")
                let newNodes = nodes.map((n) => {
                    if (savedNode.id && n.id === savedNode.id) {
                        updated = true
                        return savedNode
                    }
                    return n
                })

                if (!updated) {
                    LOGGER.debug("node not found in nodes, adding it to newNodes.")
                    newNodes.push(savedNode)
                }

                //TODO when the nodes are set, the treeview is re-rendered, and the Data Flow Diagram is re-rendered but empty :(
                LOGGER.debug("setting nodes to newNodes: ", newNodes)
                nodes = (newNodes)
                setUpdatedNode(savedNode)

                setUpdating(false)

            }, (error) => {
                const zeErrorLoadingMessage = <><span>Error Saving Node </span><span className={"retryLink"} onClick={window.location.reload(true)}>retry</span></>
                setError(zeErrorLoadingMessage)
            })
        LOGGER.debug("done calling and awaiting ModelContext.saveOneNode, savedNode:", savedNode)
        setUpdating(false)
        return savedNode
    }

    function getDescendantIds(nodeId) {
        let descendants = searchNodes((n)=>n.parentId === nodeId)
        let descendantIds = descendants.map((d)=>d.id)
        for (let descendant of descendants) {
            descendantIds = descendantIds.concat(getDescendantIds(descendant.id))
        }
        return descendantIds
    }

    async function removeNodeById(nodeId) {
        LOGGER.debug("removeNodeById.removing node(nodeId): ", nodeId)
        await removeNodesByIds([nodeId])
    }

    async function removeNodesByIds(nodeIdsToDelete, startRemoving, nodeRemoved, doneRemoving) {
        LOGGER.debug("removing nodes(nodeIdsToDelete): ", nodeIdsToDelete)
        if (!nodeIdsToDelete || nodeIdsToDelete.length === 0) {
            return
        }
        LOGGER.debug("setRemoving(true)")
        setRemoving(true)
        let allNodeIdsToDelete = [...nodeIdsToDelete]
        nodeIdsToDelete.forEach(nodeId=>{
            allNodeIdsToDelete = allNodeIdsToDelete.concat(getDescendantIds(nodeId))
        })
        //unique values
        allNodeIdsToDelete = [...new Set(allNodeIdsToDelete)]

        LOGGER.debug("calling removeNodes API")

        if (startRemoving) {
            startRemoving(nodes)
        }

        LOGGER.debug("allNodeIdsToDelete to remove:", allNodeIdsToDelete)
        let endpoint = "/.netlify/functions/manage-model-objects"

        return fetch(endpoint + "?ids=" + allNodeIdsToDelete.join(","), {
            headers: addAuthorizationHeader({}, selectedWorkspace, user),
            method: "DELETE"
        }).then(res =>
            res.json()
        ).then(nodes => {
            LOGGER.debug("deleted objects: ", nodes)
            if (nodeRemoved) {
                nodeRemoved(nodes)
            }
        }).catch(e => {
            LOGGER.error("Error POSTing capability: ", e)
        }).finally(() => {
            LOGGER.debug("done removing nodes")
            setRemoving(false)
        })
    }

    function getNodeById(nodeId) {
        if (!nodes) {
            return null
        }
        return nodes.find(node=>node.id === nodeId)
    }

    function getNodesByIds(ids) {
        if (!ids || !Array.isArray(ids) || ids.length === 0) {
            return null
        }
        return searchNodes((n)=>(ids.includes(n.id)))
        //return this.treeviewRef?.current?.getNodeById(id)
    }

    function getNodesByType(typeName) {
        if (!typeName) {
            return null
        }
        return searchNodes((n)=>(n.type === typeName))
    }

    function searchNodes(filterFunction) {
        if (!filterFunction) {
            return null
        }
        return nodes?.filter(filterFunction)
    }

    function getSiblingNodesById(nodeId) {
        if (!nodeId) {
            return null
        }
        const node = getNodeById(nodeId)
        if (!node) {
            return null
        }
        return getSiblingNodes(node)

    }

    function getSiblingNodes(node) {
        if (!node) {
            return null
        }
        return searchNodes((n)=>{return n.parentId === node.parentId})
    }

    function getNodeChildren(forNodeId) {
        if (!forNodeId) {
            return null
        }
        return searchNodes((n)=>{return n.parentId === forNodeId})
    }

    function getNodes() {
        return nodes
    }

    let model = {
        nodes,
        getNodes,
        getNodeById,
        getNodesByIds,
        getNodeChildren,
        getNodesByType,
        searchNodes,
        reload,
        saveNode,
        removeNodeById,
        removeNodesByIds,
        setUpdatedNode
    }

    useEffect(() => {
        reload()
        // eslint-disable-next-line
    }, [user, selectedWorkspace])

    return <ModelContext.Provider value={{
        model, nodes,
        getNodes, getNodeById, getNodesByIds, getNodeChildren, getNodesByType, getSiblingNodes, getSiblingNodesById,
        searchNodes,
        reload,
        saveNode, removeNodeById, removeNodesByIds, updatedNode,
        loading, removing, updating,
        error
    }}>
        {children}
    </ModelContext.Provider>
}
