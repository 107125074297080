import {download} from "../utils/DiagramUtils";
import Logger from "../../../../utils/Logger";

const LOGGER = new Logger('talShareOptions.js');

export function talShareOptions(paper, environmentInfo) {
    LOGGER.debug("talShareOptions: ", environmentInfo)
    return  [
        {
            format: "PNG",
            label: "Export as PNG image",
            executeOption: async ()=>{
                paper?.toPNG((dataURL) => {
                    download(dataURL, 'graph.png');
                });
            }
        },
        {
            format: "PPT",
            label: "Export as PowerPoint",
            disabled: true,
            executeOption: async ()=>{
                //do nothin'
            }
        },
    ]
}
