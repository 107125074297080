
import cssStyles from "./TalDiagramToolbar.module.css"
import {Checkbox, FormControlLabel, FormGroup, IconButton} from "@mui/material";
import ShareButton from "../ShareButton/ShareButton";
import React from "react";
import {showDialog} from "../utils/JointjsUtils";
import LayersClearIcon from "@mui/icons-material/LayersClear";


function clearPaperClickHandler(clearPaper) {
    showDialog({
        text: "Are you sure you wish to clear all elements from the diagram?",
        buttons: [{
            id: "yes",
            text: "YES",
            handler: () => {
                clearPaper()
            },
        }, {
            id: "no",
            text: "NO",
            handler: () => {},
            sameAsClose: true,
        } ],
    });

}

export function TalDiagramToolbar({
                                      snapToGrid, onSnapToGridValueChange,
                                      showApplications, onShowApplicationsValueChange,
                                      shareOptions, clearPaper
}) {
    return <div className={cssStyles.main}>
        <FormGroup>
            <FormControlLabel control={<Checkbox value={snapToGrid} onChange={(event)=>onSnapToGridValueChange(event.target.checked)} />} label="Snap To Grid" />
        </FormGroup>
        <FormGroup>
            <FormControlLabel control={<Checkbox value={showApplications} onChange={(event)=>onShowApplicationsValueChange(event.target.checked)} />} label="Show Applications" />
        </FormGroup>
        <ShareButton shareOptions={shareOptions}/>
        <IconButton className={cssStyles.redIconButton} aria-label="clear">
            <LayersClearIcon className={cssStyles.redIcon} onClick={()=>{clearPaperClickHandler(clearPaper)}}/>
        </IconButton>
    </div>
}
