import * as jointjs from "@joint/plus";
import {getApplicationMasterData, getApplicationSecondaryData} from "../utils/DiagramUtils";
import Logger from "../../../../utils/Logger";
import {AC_RECT_DEFAULTSIZE, AC_RECT_LABEL, ARROW_TYPES, DO_RECT_LABEL} from "./ApplicationComponentPaperConstants";
import {NodeType} from "../../../../model/Constants";
import {
   findLinksWithAttribute, getCustomProp,
    hasType,
    showDialog
} from "../utils/JointjsUtils";
import {setApplicationNodeToElement, setDataExchangeNodeOnLink, setDataObjectNodeToElement} from "./ViewTransformers";
import {DataExchange} from "../../model/DataExchange";

const LOGGER = new Logger("ApplicationComponentPaperFunctions")

export function getMatchingDataExchanges(getNodesByType, sourceApplicationId, targetApplicationId, dataObjectId) {
    const dataExchanges = getNodesByType(NodeType.DataExchange.description)
    //find the dataexchange that matches the source and target application
    //then add an indication on whether the dataObject is part of the dataexchange or not
    const matchingSourceAndTarget = dataExchanges.filter((dataExchange) => {
        return dataExchange.sourceApplicationId === sourceApplicationId && dataExchange.targetApplicationId === targetApplicationId
    })
    const withMatchingInfo = matchingSourceAndTarget.map((dataExchange) => {
        return {dataExchange: dataExchange, isExactMatch: dataExchange.dataObjectIds.includes(dataObjectId)}
    })
    return withMatchingInfo
}

export function computeFontSize(rectWidth, rectHeight, text) {
    const context = document.createElement('canvas').getContext('2d');

    let fontSize = rectHeight;  // initial value
    context.font = `${fontSize}px Arial`;

    while (context.measureText(text).width > rectWidth && fontSize > 0) {
        fontSize -= 1;  // decrease the font size
        context.font = `${fontSize}px Arial`;
    }

    return fontSize;
}

export function createDataExchange(dataObject, sourceApplication, targetApplication) {
    const dataExchange = new DataExchange()
    dataExchange.name = dataObject.name + " - " + sourceApplication.name + " -> " + targetApplication.name
    dataExchange.description = "Data exchange from " + sourceApplication.name + " to " + targetApplication.name + " of data object " + dataObject.name
    dataExchange.dataObjectIds = [dataObject.id]
    dataExchange.sourceApplicationId = sourceApplication.id
    dataExchange.targetApplicationId = targetApplication.id
    dataExchange.supportingMiddlewareIds = []
    LOGGER.trace("saving dataExchange:", dataExchange)
    return dataExchange
}

export function createDataExchangeLink(getNodeById, removeNodeById, paper, sourceRectangleId, targetRectangleId, selectedArrowType, df, setStatusMessage) {
    const graph = paper.model
    const link = createLink(graph,
        sourceRectangleId,
        targetRectangleId,
        selectedArrowType,
        df?.dataObjectIds?.map(doId => (getNodeById(doId)?.name || "-")).join(", "),
    )
    setDataExchangeNodeOnLink(
        getNodeById,
        removeNodeById,
        paper,
        df, link,
        link.sourceElement,
        link.targetElement
    )
    if (!link) {
        setStatusMessage("Can't create link")
        //shouldn't happen, but you never know...
        LOGGER.debug("undefined link, strange...")
        return
    }
    graph.addCell(link); //add it to the graph, otherwise we can't add the tools...
    addToolsToLink(removeNodeById, paper, link)
    return link;
}

export function revealDataExchange(
    getNodeById,
    removeNodeById,
    paper,
    setStatusMessage,
    dataExchangeId,
    sourceRectangleId,
    targetRectangleId
) {

    const dataExchangeNode = getNodeById(dataExchangeId)
    if (!dataExchangeNode) {
        LOGGER.debug("dataExchangeNode not found, returning")
        setStatusMessage("Data exchange not found")
        return
    }

    const graph = paper.model
    const links = findLinksWithAttribute(graph, "dataExchangeId", dataExchangeId)

    if (links.length > 0) {
        /*
        make sure source and target are properly set, because sometimes the link is not properly set
         */
        links.forEach(link => {
            link.source(graph.getCell(sourceRectangleId))
            link.target(graph.getCell(targetRectangleId))
            setDataExchangeNodeOnLink(
                getNodeById,
                removeNodeById,
                dataExchangeNode,
                link,
                sourceRectangleId,
                targetRectangleId,
                (dataExchangeId) => {
                    setStatusMessage("Deleting data exchange: ", dataExchangeId)
                    //onNodeDeleteHandler(dataExchangeId)
                }
            )
        })
        LOGGER.debug("data exchange already exists, returning")
        setStatusMessage("Data exchange already on graph")
        return
    }

    const dataExchangeLink = createDataExchangeLink(
        getNodeById,
        removeNodeById,
        paper,
        sourceRectangleId,
        targetRectangleId,
        "manhattan",
        dataExchangeNode,
        function(zeLink) {
            setStatusMessage("Deleting data exchange")
            removeNodeById(dataExchangeId)
            zeLink.remove()
        },
        setStatusMessage
    )
    if (dataExchangeLink) {
        setDataExchangeNodeOnLink(
            getNodeById,
            removeNodeById,
            paper,
            dataExchangeNode,
            dataExchangeLink,
            sourceRectangleId,
            targetRectangleId,
            (dataExchangeId) => {
                setStatusMessage("Deleting data exchange: ", dataExchangeId)
                //onNodeDeleteHandler(dataExchangeId)
            }
        )
    }
    return dataExchangeLink
}

export function getApplicationViewAtPosition(graph, x, y) {
    return graph.findModelsFromPoint({x: x, y: y}).find((cell) => hasType(cell, NodeType.Application.description))
}
export function getDataObjectViewAtPosition(graph, x, y) {
    return graph.findModelsFromPoint({x: x, y: y}).find((cell) => hasType(cell, NodeType.DataObject.description))
}

export function createDataObjectRectangle(
    startY, width,
    dataObject, applicationRectangle, index, doHeightJump, paper, role="master") {
    const fontSize = computeFontSize(width - DO_RECT_LABEL.paddingSides * 2, DO_RECT_LABEL.height, (dataObject?.name || "ERROR"))

    let borderColor = "#222222" //master border color
    if (role === "slave") {
        borderColor = "#AAAAAA"
    }

    let mdRectangle = new jointjs.shapes.standard.Rectangle({
        position: {
            x: applicationRectangle.attributes.position.x + DO_RECT_LABEL.paddingSides,
            y: applicationRectangle.attributes.position.y + AC_RECT_LABEL.height + index * doHeightJump + startY
        },
        size: {width: width - 2 * DO_RECT_LABEL.paddingSides, height: DO_RECT_LABEL.height},
        magnet: false,
        attrs: {
            fill: 'white',
            body: {
                fill: '#9fc9f5',
                stroke: borderColor,
                strokeWidth: 1,
            },
            label: {
                fontSize: Math.min(fontSize, DO_RECT_LABEL.defaultFontSize),
                text: (dataObject?.name || "-"),
                textWrap: {
                    width: AC_RECT_DEFAULTSIZE.width - DO_RECT_LABEL.paddingSides * 2,
                    height: DO_RECT_LABEL.height, // height restriction
                    ellipsis: true // ellipsis after the wrap
                }
            },
        },
    });

    setDataObjectNodeToElement(paper.model, dataObject, applicationRectangle, mdRectangle, role)
    mdRectangle.addTo(paper.model)
    applicationRectangle.embed(mdRectangle)
}



export function createApplicationComponentRectangle(
    paper,
    getNodeById,
    applicationNode,
    x, y,
    showMasterData
) {

    if (!applicationNode) {
        LOGGER.debug("no applicationNode")
        return
    }

    const mds = (showMasterData?getApplicationMasterData(getNodeById, applicationNode.id):[])
    const sds = (showMasterData?getApplicationSecondaryData(getNodeById, applicationNode.id):[])


    const numberOfMasterDataObjects = mds.length
    const numberOfDataObjects = sds.length

    const doHeightJump = DO_RECT_LABEL.height + DO_RECT_LABEL.paddingHeight

    const height = Math.max((numberOfMasterDataObjects + numberOfDataObjects) * DO_RECT_LABEL.height + AC_RECT_LABEL.height + DO_RECT_LABEL.paddingHeight, 50)
    const width = 100

    let label = {
        height: AC_RECT_LABEL.height,
        text: applicationNode.name,
        textWrap: {
            width: 90, // reference width minus 10
            height: AC_RECT_LABEL.height,
            ellipsis: true // ellipsis after the wrap
        }
    }

    if (numberOfDataObjects>0 || numberOfMasterDataObjects>0) {
        label.refY = -10
    }
    const attributes = {
        position: {x: x, y: y},
        size: {width: width, height: height},
        attrs: {
            fill: 'skyblue',
            stroke: 'skyblue',
            strokeWidth: 1,
            body: {
                fill: 'skyblue',
                stroke: 'skyblue',
                strokeWidth: 1,
            },
            label: label
        }
    }

    let applicationRectangle = new jointjs.shapes.standard.Rectangle(attributes);
    setApplicationNodeToElement(applicationNode, applicationRectangle)
    applicationRectangle.addTo(paper.model);

    mds.forEach((md, index) => {
        createDataObjectRectangle(0, width,md, applicationRectangle, index, doHeightJump, paper, "master");
    })

    sds.forEach((sd, index) => {
        createDataObjectRectangle(numberOfMasterDataObjects*doHeightJump, width, sd, applicationRectangle, index, doHeightJump, paper, "slave");
    })

    return applicationRectangle;
}

export function updateApplicationRectangle(applicationNode, cell, paper) {
    //this was to make sure that Labels don't
    if (applicationNode?.name) {
        cell.attr("label/text", applicationNode.name)
    } else {
        cell.attr("label/text", "-")
    }
    cell.attr("label/textWrap/height", 90)
    cell.attr("label/textWrap/height", AC_RECT_LABEL.height)
    cell.attr("label/textWrap/ellipsis", true)
}

export function updateDataObjectRectangle(dataObjectNode, cell) {
    //this was to make sure that Labels don't
    if (dataObjectNode?.name) {
        cell.attr("label/text", dataObjectNode.name)
    } else {
        cell.attr("label/text", "-")
    }
    cell.attr("label/textWrap/height", 90)
    cell.attr("label/textWrap/height", DO_RECT_LABEL.height)
    cell.attr("label/textWrap/ellipsis", true)
}

export function addToolsToLink(removeNodeById, paper, link) {
    if (!link) {
        LOGGER.debug("no link")
        return
    }

    let linkView = link.findView(paper);
    if (!linkView) {
        LOGGER.debug("no linkView")
        return
    }

    let verticesTool = new jointjs.linkTools.Vertices();
    let segmentsTool = new jointjs.linkTools.Segments();
    let removeButton = new jointjs.linkTools.Remove({
        action: function(evt) {
            evt.stopPropagation();

            showDialog({
                text: "Are you sure you want to delete this Data Exchange?",
                buttons: [
                    {
                        id: 'yes',
                        text: 'YES',
                        handler: () => {
                            const dataExchangeId = getCustomProp(link, "dataExchangeId")
                            if (dataExchangeId) {
                                LOGGER.debug("removing dataExchangeId", dataExchangeId)
                                removeNodeById(dataExchangeId)
                            } else {
                                LOGGER.debug("no dataExchangeId, strange...")
                            }
                            LOGGER.debug("removing link on paper")
                            link.remove()
                        }
                    }, {
                        id: 'no',
                        text: 'NO',
                        handler: () => {
                            LOGGER.debug("no")
                        },
                        sameAsClose: true,
                    }
                ]

            })
        }
    })
    const hideButton = new jointjs.linkTools.Button({
        markup: [{
            tagName: 'circle',
            selector: 'button',
            attributes: {
                'r': 7,
                'stroke': 'blue',
                'stroke-width': 3,
                'fill': 'blue',
                'cursor': 'pointer'
            }
        }, {
            tagName: 'text',
            textContent: '-',
            selector: 'icon',
            attributes: {
                'fill': 'white',
                'font-size': 10,
                'text-anchor': 'middle',
                'font-weight': 'bold',
                'pointer-events': 'none',
                'y': '0.3em'
            }
        }],
        distance: -77,
        action: function() {
            // just remove the link from the graph and don't delete the whole dataexchange
            link.remove()
        }
    });

    linkView.addTools(new jointjs.dia.ToolsView({
        tools: [verticesTool, segmentsTool, removeButton, hideButton]
    }));
}
export function addToolsToComponentRectangle(cell, paper) {
    let elementView = cell.findView(paper);
    if (elementView?.toolsView?.children?.length > 0) {
        LOGGER.debug("tools already added")
        return
    }

    const type = getCustomProp(cell, "type")

    let tools = []
    //kudos https://resources.jointjs.com/tutorial/element-tools
    let boundaryTool = new jointjs.elementTools.Boundary();
    if (type === NodeType.Application.description) {
        let deleteText = "Are you sure you want to remove the Application from the diagram?"
        let removeButton = new jointjs.elementTools.Remove({
            action: function(evt) {
                evt.stopPropagation();
                showDialog({
                    text: deleteText,
                    buttons: [
                        {
                            id: 'yes',
                            text: 'YES',
                            handler: () => {
                                cell.remove()
                            }
                        }, {
                            id: 'no',
                            text: 'NO',
                            handler: () => {
                                LOGGER.debug("no")
                            },
                            sameAsClose: true,
                        },
                    ]
                });
            }
        });
        tools = [boundaryTool, removeButton]
        let toolsView = new jointjs.dia.ToolsView({
            tools: tools
        });
        elementView.addTools(toolsView);
        elementView.hideTools();

        paper.on('element:mouseenter', function (elementView) {
            elementView.showTools();
        });

        paper.on('element:mouseleave', function (elementView) {
            elementView.hideTools();
        });

    } else if (type === NodeType.DataObject.description) {
        tools = []
    }

}

export function createLink(graph, sourceElementId, targetElementId, arrowType, labelText) {

    if (sourceElementId === targetElementId) {
        LOGGER.debug("source and target are the same, not creating link")
        return
    }
    if (sourceElementId === undefined || targetElementId === undefined) {
        LOGGER.debug("source or target is undefined, not creating link")
        return
    }
    if (graph.getCell(sourceElementId) === undefined || graph.getCell(targetElementId) === undefined) {
        LOGGER.debug("source or target is not in graph, not creating link")
        return
    }

    //const onClickHandler = onClick || function() {LOGGER.debug("onClickHandler not defined")}

    LOGGER.debug("createLink", arrowType)

    let linkOptions = {
        source: {
            id: sourceElementId,
            anchor: {
                name: 'center'
            }
        },
        target: {
            id: targetElementId,
            anchor: {
                name: 'center'
            }
        },
        router: ARROW_TYPES[arrowType]?.router,
        connector: ARROW_TYPES[arrowType]?.connector,
        line: {
            stroke: 'black',
            strokeWidth: 2,
            targetMarker: {
                type: 'path',
                d: 'M 10 -5 0 0 10 5 z'
            }
        },
        vertices: ([]),
    };

    let link = new jointjs.shapes.standard.Link(linkOptions);

    link.appendLabel({
        attrs: {
            text: {
                text: labelText,
                y: -5,
            }
        }
    })

    //link.addTo(graph);
    return link
}
