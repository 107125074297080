
import cssStyles from './SupportDocumentationLink.module.css'
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

const BASED_DOC_URL="https://kenchiku.freshdesk.com/support/solutions/articles/"

export default function SupportDocumentationLink({documentId, children}) {
    //example url (for the model link): https://kenchiku.freshdesk.com/support/solutions/articles/151000104693-the-model
    let zeDocuId = (documentId?documentId:"")

    return <div className={cssStyles.supportLink}>
        <a className={cssStyles.supportLinkTextLink} href={BASED_DOC_URL + zeDocuId}>{children}</a>
        <a className={cssStyles.supportLinkIconLink} href={BASED_DOC_URL + zeDocuId} target={"_blank"} rel="noreferrer"><OpenInNewIcon width={"10"} height={"10"}/></a>
    </div>
}
