//https://rogulski.it/blog/react-material-checkbox-form-validation/
//https://www.youtube.com/watch?v=10Z0OCPfubI

import cssStyles from "./DataExchangeEditor.module.css"

import {useEffect, useMemo} from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField} from "@mui/material";
import NodeSelector from "./selectors/NodeSelector";

import {useForm} from "react-hook-form"
import {object, string, date, array} from 'yup';
import {useYupValidationResolver} from "./EditorUtils"
import Logger from "../../../utils/Logger"
import {NodeFolderRootIds, NodeType} from "../../../model/Constants";
import {MultilineTextField} from "./fields/MultilineTextField";

const LOGGER = new Logger("DataExchangeEditor")

export const DATA_EXCHANGE_SCHEMA = object().shape({
    //id: string().required(),
    name: string().required(),
    description: string().required(),
    parentId: string().required().default(NodeFolderRootIds.DataExchangeRootId.description),
    sourceApplicationId: string().required(),
    targetApplicationId: string().required(),
    dataObjectIds: array().of(string()).default([]),
    createdOn: date().default(() => new Date()),
    updatedOn: date().default(() => new Date()),
    supportingMiddlewareIds: array().of(string()).default([]),
});

const PARENT_NODE_LABEL = "Parent Data Exchange"
const PARENT_NODE_ROOT_ID = NodeFolderRootIds.DataExchangeRootId.description
const PARENT_NODE_TYPE = NodeType.DataExchange.description
const PARENT_NODE_TYPE_NAME = "Data Exchange"


async function createObject(
    id,
    name,
    description,
    parentId,
    sourceApplicationId,
    targetApplicationId,
    dataObjectIds,
    supportingMiddlewareIds,
) {
    LOGGER.trace("createObject")
    let validationObject = {
        id,
        name,
        description,
        parentId,
        sourceApplicationId,
        targetApplicationId,
        dataObjectIds,
        supportingMiddlewareIds,
        type: PARENT_NODE_TYPE
    }
    LOGGER.trace("validationObject: ", validationObject)
    const node = await DATA_EXCHANGE_SCHEMA.validate(validationObject)
    LOGGER.trace("done validating, returning node:", node)
    return node
}

export default function DataExchangeEditor({
                              isOpen,
                              titleOfForm,
                              descriptionOfForm,
                              node,
                              onClose,
                              onCancel
                          }) {

    const handleClose = async (data, reason) => {
        LOGGER.debug("data:", data)
        LOGGER.debug("reason:", reason)
        if (reason && ["escapeKeyDown", "backdropClick"].includes(reason)) {
            handleCancel()
            return
        }
        onClose(await createObject(
            data.id,
            data.name,
            data.description,
            data.parentId,
            data.sourceApplicationId,
            data.targetApplicationId,
            data.dataObjectIds,
            data.supportingMiddlewareIds
        ))
    };
    const handleCancel = () => {
        onCancel(node.id)
    };

    const defaultValues = useMemo(()=>({
        id:node?.id,
        name: "[name]",
        description: "[description]",
        parentId: node?.parentId,
        sourceApplicationId: node?.sourceApplicationId,
        targetApplicationId: node?.targetApplicationId,
        dataObjectIds: ((node?.dataObjectIds)?node.dataObjectIds:[]),
        supportingMiddlewareIds: [],
    }), [node])

    const resolver = useYupValidationResolver(DATA_EXCHANGE_SCHEMA);
    const { register, handleSubmit, formState: {errors}, setValue, control } = useForm({
        resolver,
        defaultValues
    });

    useEffect(()=>{
        if (isOpen && node) {
            /*
            This construct is necessary to set all the values for all the fields in the form.
            We used to use Object.keys(node
             */
            Object
                .values(DATA_EXCHANGE_SCHEMA._nodes)
                .forEach((k) => {
                    const tempVal = (node[k]?node[k]:defaultValues[k])
                    LOGGER.debug(`setting value '${tempVal}' for key: ${k}`)
                    setValue(k, tempVal)
                })
        }
    }, [isOpen, node, setValue, defaultValues])

    useEffect(()=>{
        LOGGER.debug("errors:", errors)
    }, [errors])

    return (
        <div>
            <Dialog open={isOpen} onClose={handleClose}>
                <DialogTitle className={cssStyles.dialogTitle}>{titleOfForm}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {descriptionOfForm}
                    </DialogContentText>
                    <form
                        data-testid={"data-exchanges-editor-form"}
                        className={cssStyles.form}
                        onSubmit={handleSubmit(data => handleClose(data))}
                        noValidate
                    >
                        <input
                            type={"hidden"}
                            name={"id"}
                            value={node.id}
                            {...register("id")}
                        />
                        <TextField
                            autoFocus
                            margin="dense"
                            id="name"
                            name={"name"}
                            label="Name"
                            type="text"
                            fullWidth
                            variant="standard"
                            {...register("name")}
                            error={!!errors?.name}
                            helperText={errors?.name ? errors.name.message : null}
                        />
                        <MultilineTextField
                            register={register}
                            errors={errors}
                        />
                        <NodeSelector
                            label={PARENT_NODE_LABEL}
                            nodeType={PARENT_NODE_TYPE}
                            nodeTypeName={PARENT_NODE_TYPE_NAME}
                            nodeRootFolderId={PARENT_NODE_ROOT_ID}
                            multiSelect={false}
                            name={"parentId"}
                            control={control}
                            error={!!errors?.parentId}
                            helperText={errors?.parentId ? errors.parentId.message : null}
                        />
                        <NodeSelector
                            label={"Source Application"}
                            nodeType={NodeType.Application.description}
                            nodeTypeName={"Application"}
                            nodeRootFolderId={NodeFolderRootIds.ApplicationRootId.description}
                            multiSelect={false}
                            name={"sourceApplicationId"}
                            control={control}
                            error={!!errors?.sourceApplicationId}
                            helperText={errors?.sourceApplicationId ? errors.sourceApplicationId.message : null}
                        />
                        <NodeSelector
                            label={"Target Application"}
                            nodeType={NodeType.Application.description}
                            nodeTypeName={"Application"}
                            nodeRootFolderId={NodeFolderRootIds.ApplicationRootId.description}
                            multiSelect={false}
                            name={"targetApplicationId"}
                            control={control}
                            error={!!errors?.targetApplicationId}
                            helperText={errors?.targetApplicationId ? errors.targetApplicationId.message : null}
                        />
                        <NodeSelector
                            label={"Data Objects"}
                            nodeType={NodeType.DataObject.description}
                            nodeTypeName={"Data Objects"}
                            nodeRootFolderId={NodeFolderRootIds.DataObjectRootId.description}
                            multiSelect={true}
                            name={"dataObjectIds"}
                            control={control}
                            error={!!errors?.dataObjectIds}
                            helperText={errors?.dataObjectIds ? errors.dataObjectIds.message : null}
                        />
                        <NodeSelector
                            label={"Supporting Middlewares"}
                            nodeType={NodeType.Middleware.description}
                            nodeTypeName={"Supporting Middlewares"}
                            nodeRootFolderId={NodeFolderRootIds.MiddlewareRootId.description}
                            multiSelect={true}
                            name={"supportingMiddlewareIds"}
                            control={control}
                            error={!!errors?.supportingMiddlewareIds}
                            helperText={errors?.supportingMiddlewareIds ? errors.supportingMiddlewareIds.message : null}
                        />
                        <DialogActions className={cssStyles.actionsDiv}>
                            <Button onClick={handleCancel}>Cancel</Button>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                className={cssStyles.submit}
                            >Save</Button>
                        </DialogActions>
                    </form>
                </DialogContent>
            </Dialog>
        </div>
    );
}
