import cssStyles from "./ClickableInlineObjectList.module.css"
import {useModel} from "../../model/ModelContext";
import {useSelectedNodes} from "../SelectedNodes/SelectedNodesProvider";
import {NODE_FILL_COLORS} from "../../utils/NodeTypeColors";

export function ClickableInlineObjectList({idList}) {
    const {getNodeById} = useModel()
    const {setSoftSelectedNodeById} = useSelectedNodes()
    let objectList = []

    if (idList) {
        objectList = idList.map(id => getNodeById(id))
    }
    const onClick = (objectId) => {
        console.log("Clicked on objectId: ", objectId)
        setSoftSelectedNodeById(objectId)
    }
    return (
        objectList.map((object, index) => {
            const color = NODE_FILL_COLORS[object.type]
            return (
                <span key={index} className={cssStyles.objectListName} style={{color:color}} onClick={() => onClick(object.id)}>{object.name}</span>
            )
        })
    )
}
