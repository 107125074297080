export function addClasses(classes) {
    if (Array.isArray(classes)) {
        return classes.join(" ")
    } else {
        return classes
    }
}

export function formatDate(date) {
    let day = date.getDate().toString().padStart(2, '0');
    let month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
    let year = date.getFullYear();

    return `${day}/${month}/${year}`;
}

export function calculatePercentage(numberString, totalString){
    const zeNumber = (typeof numberString === "string"?parseInt(numberString):numberString);
    const zeTotal = (typeof totalString === "string"?parseInt(totalString):totalString);
    let percentage="";
    if(isNaN(zeNumber) || isNaN(zeTotal)){
        percentage=0;
    }else{
        percentage = ((zeNumber/zeTotal) * 100).toFixed(2);
    }

    return percentage
}

export function displayString(str, defaultString="-") {
    return (str?str:defaultString)
}
