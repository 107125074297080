import cssStyles from "./TreeContextMenu.module.css"
import {useRef} from "react";
import useOnClickOutside from "../TreeComponent2/hooks/useOnClickOutside";
import useOnEscape from "../TreeComponent2/hooks/useOnEscape";
import {isRootFolder} from "../../../utils/NodeUtils";

//kudos to https://www.youtube.com/watch?v=mX4MqIdw1KM

export default function TreeContextMenu({x, y, node, closeContextMenu, onActionSelected}) {

    const mainRef = useRef(null)


    useOnClickOutside(mainRef, closeContextMenu)
    useOnEscape(closeContextMenu)

    let nodeType = node?.data?.type
    if (nodeType === "folder") {
        const folderTypeLabelMapping = {
            "_actors":"actor",
            "_actor_activities":"actor_activity",
            "_applications":"application",
            "_architecture_building_blocks":"architecture_building_block",
            "_business_processes":"business_process",
            "_capabilities":"capability",
            "_capability_analysis":"capability_analysis",
            "_data_exchanges":"data_exchange",
            "_data_flows":"data_flow",
            "_data_objects":"data_object",
            "_design_decisions":"design_decision",
            "_domains":"domain",
            "_functionalities":"functionality",
            "_maturity_model_analysis":"maturity_model_analysis",
            "_middlewares":"middleware",
            "_principles":"principle",
            "_scenarios":"scenario",
            "_tals":"tal",
            "_views":"view",
        }
        nodeType = folderTypeLabelMapping[node.id]
    }

    const folderTypeLabelMapping = {
        "actor":"Actor",
        "application":"Application",
        "architecture_building_block":"Architecture Building Block",
        "business_process":"Business Process",
        "capability":"Capability",
        "capability_analysis":"Capability Analysis",
        "data_exchange":"Data Exchange",
        "data_flow":"Data Flow",
        "data_object":"Data Object",
        "design_decision":"Design Decision",
        "domain":"Domain",
        "functionality":"Functionality",
        "maturity_model_analysis":"Maturity Model Analysis",
        "middleware":"Middleware",
        "principle":"Principle",
        "scenario":"Scenario",
        "tal":"Tal",
        "view":"View",
    }
    let nodeTypeLabel = folderTypeLabelMapping[nodeType]



    const nodeTitle = node.text
    const yCorrection = 100

    return <div ref={mainRef} className={cssStyles.main} style={{top: `${y - (yCorrection)}px`, left: `${x}px`}} onClick={closeContextMenu}>
        <div className={cssStyles.optionWrapper}>
            <div className={cssStyles.title}>
                <span>{nodeTitle}</span>
            </div>
            <div className={cssStyles.separator}></div>
            <div className={cssStyles.option}>
                <span data-testid={"tree-context-menu-new-span"} onClick={()=>onActionSelected({name:"NEW", type:nodeType, node:node})}>New <b>{nodeTypeLabel}</b></span>
            </div>
            {
                !isRootFolder(node) &&
                <div className={cssStyles.option}>
                    <span data-testid={"tree-context-menu-edit-span"} onClick={()=>onActionSelected({name:"EDIT", type:nodeType, node:node})}>Edit <b>{nodeTitle}</b></span>
                </div>
            }
            {

                <div className={cssStyles.option}>
                    <span data-testid={"tree-context-menu-display-span"} onClick={() => onActionSelected({
                        name: "DISPLAY",
                        type: nodeType,
                        node: node
                    })}>Show {(isRootFolder(node)?"grid":"diagram")} for <b>{nodeTitle}</b></span>
                </div>
            }
            {
                isRootFolder(node) && <>
                    <div className={cssStyles.separator}></div>
                    <div className={cssStyles.option}>
                        <span data-testid={"tree-context-menu-import-span"} onClick={()=>onActionSelected({name:"IMPORT_CSV", type:nodeType, node:node})}>{"Import CSV"}</span>
                    </div>
                </>
            }

            {
                !isRootFolder(node) && <>
                    <div className={cssStyles.separator}></div>
                    <div className={cssStyles.option}>
                        <span data-testid={"tree-context-menu-delete-span"} onClick={()=>onActionSelected({name:"DELETE", type:nodeType, node:node})}>{"Delete '" + nodeTitle + "'"}</span>
                    </div>
                </>
            }
        </div>
    </div>
}
