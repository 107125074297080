import cssStyles from "./ViewDiagram2.module.css"
import React, {forwardRef, useEffect, useRef, useState} from "react";
import Logger from "../../../utils/Logger";
import {Checkbox, FormControl, FormControlLabel, IconButton} from "@mui/material";
import HighlightAltIcon from '@mui/icons-material/HighlightAlt';
import ApplicationComponentPaper from "./ViewDiagram2/ApplicationComponentPaper";
import {isEqual} from "lodash";
import {showDialog} from "./utils/JointjsUtils";
import {addClasses} from "../../../utils/PresentationUtils";
import DataExchangeGrid from "./ViewDiagram2/DataExchangeGrid";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import 'react-tabs/style/react-tabs.css';
import ApplicationGrid from "./ViewDiagram2/ApplicationGrid";
import ShareButton from "./ShareButton/ShareButton";
import {download} from "./utils/DiagramUtils";
import RefreshIcon from '@mui/icons-material/Refresh';
import LayersClearIcon from '@mui/icons-material/LayersClear';
import {useModel} from "../../../model/ModelContext";
import {useDrop} from "react-dnd";
import {useTreeDndContext} from "./TreeDndContext";
import {ItemTypes} from "@minoru/react-dnd-treeview";

const LOGGER = new Logger("ViewDiagram2", 1)

function viewShareOptions(paper) {
    LOGGER.debug("getting viewShareOptions")
    return  [
        {
            format: "PNG",
            label: "Export as PNG image",
            executeOption: async ()=>{
                paper?.toPNG((dataURL) => {
                    download(dataURL, 'graph.png');
                });
            }
        },
    ]
}

function ViewDiagram2({
                         node,
                         isPlaywrightMouseDownOnNode,
                     }, ref) {

    const {isDraggingActive, nodeBeingDragged} = useTreeDndContext()

    const {saveNode, getNodeById} = useModel()

    const diagramRef = useRef()
    const applicationsDropDivRef = useRef()
    const applicationComponentPaperRef = useRef()

    const labelShowGrid = { label:"Show grid" };
    const labelAddLinks = { label:"Auto-add data exchanges on App drop" };
    const labelDataObjects = { label:"Show Data Objects" };
    const labelSelection = { label:"Select modus" };

    const [automaticallyAddLinks, setAutomaticallyAddLinks] = useState(true)
    const [showMasterData, setShowMasterData] = useState(true)

    const [isSelectionActive, setIsSelectionActive] = useState(false)

    const [canAcceptDrop, setCanAcceptDrop] = useState(false)

    useEffect(() => {
        if (isDraggingActive) {

            switch (nodeBeingDragged?.type) {
                case "application":
                case "data_object":
                    setCanAcceptDrop(true)
                    break
                default:
                    setCanAcceptDrop(false)
                    break
            }
        } else {
            setCanAcceptDrop(false)
        }
    }, [isDraggingActive, nodeBeingDragged]);


    const [{ isOver, isOverCurrent }, drop] = useDrop(
        () => ({
            accept: ItemTypes.TREE_ITEM, //['NODE', 'ApplicationComponent'],
            canDrop(_item, monitor) {
                const itemType = _item?.data?.type
                switch (itemType) {
                    case "application":
                    case "data_object":
                    case "NODE":
                        return true
                    default:
                        return false
                }
            },
            hover(_item, monitor) {
                applicationComponentPaperRef.current.setHoverClientOffset(monitor.getClientOffset())
            },
            drop(_item, monitor) {
                LOGGER.trace(`isOver=${isOver}, ìsOverCurrent=${isOverCurrent}`)
                LOGGER.debug("DROP! _item=", _item)
                //LOGGER.debug("DROP! monitor", monitor)
                LOGGER.debug("DROP! monitor.getClientOffset()", monitor.getClientOffset())
                LOGGER.debug("DROP! monitor.didDrop()", monitor.didDrop())
                applicationComponentPaperRef.current.endHover()
                const didDrop = monitor.didDrop()
                if (didDrop) {
                    return _item
                }

                if (diagramRef) {
                    const itemType = monitor?.getItemType()
                    switch (itemType) {
                        case ItemTypes.TREE_ITEM:
                            const objectType = _item?.data?.type
                            switch (objectType) {
                                case "application":
                                    LOGGER.debug("drop.monitor.getClientOffset(): ", monitor.getClientOffset())
                                    //add the node to the diagram...
                                    const node = getNodeById(_item.id)
                                    applicationComponentPaperRef.current.addNode(node, monitor.getClientOffset().x, monitor.getClientOffset().y)
                                    break
                                default:
                                    break
                            }

                            break
                        default:
                            LOGGER.debug("unsupported item type '" + itemType + "' dropped: ", _item)
                            break
                    }

                } else {
                    LOGGER.debug("diagramRef undefined")
                }

                return _item
            },
            collect: (monitor) => ({
                isOver: monitor.isOver(),
                isOverCurrent: monitor.isOver({ shallow: true }),
                clientOffset: monitor.getClientOffset() ,
            }),
        }),
        [] /* don't forget the dependecies! kudos to: https://stackoverflow.com/a/70757948*/,
    )

    //TODO
    /*
    function handleAddDataObject(nodeId, dataObjectId) {
        const application = getNodeById(nodeId)
        if (!application) {
            LOGGER.debug("application not found")
            return
        }
        const dataObject = getNodeById(dataObjectId)
        if (!dataObject) {
            LOGGER.debug("dataObject not found")
            return
        }
        if (!application.masterDataObjectIds) {
            application.masterDataObjectIds = []
        }
        application.masterDataObjectIds.push(dataObjectId)
        LOGGER.trace("saving application:", application)
        onNodeSave(application)
    }

     */

    drop(applicationsDropDivRef)

    //TODO
    function handleOnDiagramClick(event) {
        //onSelectNodeCallback(view.id)
    }


    //TODO
    /*
    function onSelectionRectangleDrawn(rectangle) {

    }
     */

    function refreshPaper() {
        applicationComponentPaperRef.current.refreshPaper()
    }

    function clearPaper() {
        showDialog({
            text: "Are you sure you wish to clear all elements from the diagram?",
            buttons: [{
                id: "yes",
                text: "YES",
                handler: () => {
                    applicationComponentPaperRef.current.clearPaper()
                },
            }, {
                id: "no",
                text: "NO",
                handler: () => {},
                sameAsClose: true,
            } ],
        });

    }

    const [isGridOpen, setIsGridOpen] = useState(true);

    const [nodeIdsOnTheGraph, setNodeIdsOnTheGraph] = useState([])

    const [selectedDataExchangeIds, setSelectedDataExchangeIds] = useState([])

    return (<div ref={ref} className={cssStyles.main}>
        {node?.name}
        <hr/>
        <div className={cssStyles.filterPane}>
            <FormControl component="fieldset">
                <FormControlLabel
                    {...labelShowGrid}
                    control={<Checkbox defaultChecked />}
                    value={isGridOpen}
                    onChange={(event)=>{setIsGridOpen(oldValue=>!oldValue)}}
                />
            </FormControl>
            <FormControl component="fieldset">
                <FormControlLabel
                    {...labelAddLinks}
                    control={<Checkbox defaultChecked />}
                    value={automaticallyAddLinks}
                    onChange={(event)=>{setAutomaticallyAddLinks(oldValue=>!oldValue)}}
                />
            </FormControl>
            <FormControl component="fieldset">
                <FormControlLabel
                    {...labelDataObjects}
                    control={<Checkbox defaultChecked />}
                    value={showMasterData}
                    onChange={(event)=>{setShowMasterData(oldValue=>!oldValue)}}
                />
            </FormControl>
            <FormControl component="fieldset">
                <FormControlLabel
                    {...labelSelection}
                    control={<Checkbox
                        inputProps={{'aria-label': 'Checkbox demo'}}
                        icon={<HighlightAltIcon />}
                        checkedIcon={<HighlightAltIcon />}
                    />}
                    checked={isSelectionActive}
                    onChange={(event)=>{
                        LOGGER.debug("event:", event)
                        setIsSelectionActive(oldValue=>!oldValue)
                    }}
                />
            </FormControl>
            <FormControl component="fieldset">
                <ShareButton shareOptions={viewShareOptions(applicationComponentPaperRef?.current?.getPaper())}/>
            </FormControl>
            <IconButton className={cssStyles.iconButton} aria-label="refresh" onClick={()=>{refreshPaper(node.graph)}}>
                <RefreshIcon className={cssStyles.icon}/>
            </IconButton>
            <IconButton className={cssStyles.redIconButton} aria-label="clear" onClick={()=>{clearPaper(node.graph)}}>
                <LayersClearIcon className={cssStyles.redIcon}/>
            </IconButton>
        </div>
        <div
            className={addClasses([cssStyles.applicationsDiv, (isGridOpen?cssStyles.gridShowing:"")])}
            ref={diagramRef}
            onClick={handleOnDiagramClick}
            styles={{backgroundColor: (isOver? "lightgreen":"white")}}
        >
            <div className={cssStyles.diagramWrapperDiv}>
                <div
                    data-testid={"tal-dropRef-div"}
                    ref={applicationsDropDivRef}
                    className={((canAcceptDrop || isPlaywrightMouseDownOnNode)?cssStyles.dropZoneActive:cssStyles.dropZoneInactive)}
                ></div>
                <ApplicationComponentPaper
                    ref={applicationComponentPaperRef}
                    automaticallyAddLinks={automaticallyAddLinks}
                    showMasterData={showMasterData}
                    node={node}
                    selectedDataExchangeIds={selectedDataExchangeIds}
                    updateDataExchangeNodeIdsOnPaper={(dataExchangeIdsOnPaper)=>{
                        LOGGER.debug("ApplicationComponentPaper.updateDataExchangeNodeIdsOnPaper:", dataExchangeIdsOnPaper)
                        //TODO Make sure the exchanges are on the graph, and if they're not, add them
                        setSelectedDataExchangeIds(dataExchangeIdsOnPaper)
                    }}
                    onSaveGraphJSON={(graphJSON)=>{
                        LOGGER.debug("onSaveGraphJSON.graphJSON:", graphJSON)
                        //TODO !!! this fucks up existing views' .graph attributes when switching rapidly between view
                        if (!isEqual(graphJSON, node.graph)) {
                            node.graph = graphJSON
                            saveNode(node)
                        } else {
                            LOGGER.debug("graphJSON not changed")
                        }
                    }}
                    updateApplicationNodeIdsOnPaper={(nodeIds)=>{
                        setNodeIdsOnTheGraph(nodeIds)
                    }}
                />
            </div>
            <div className={cssStyles.gridDiv}>

                <Tabs className={cssStyles.tabContainer}>
                    <TabList>
                        <Tab>Applications</Tab>
                        <Tab>Data Exchanges</Tab>
                    </TabList>
                    <TabPanel className={cssStyles.gridTabPanel}>
                        <ApplicationGrid
                            applicationNodeIds={nodeIdsOnTheGraph}
                        />
                    </TabPanel>
                    <TabPanel className={cssStyles.gridTabPanel}>
                        <DataExchangeGrid
                            nodeIds={selectedDataExchangeIds}
                            updateSelectedDataExchangeIds={(selectedDEIs)=>{
                                LOGGER.debug("selectedDFIs:", selectedDEIs)
                                //TODO Make sure the exchanges are on the graph, and if they're not, add them
                                setSelectedDataExchangeIds(selectedDEIs)
                            }}
                        />
                    </TabPanel>

                </Tabs>


            </div>
        </div>

    </div>)
}
export default forwardRef(ViewDiagram2)
