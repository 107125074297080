import cssStyles from "./PropertyPane.module.css"
import {Button, Fade} from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import ShareIcon from '@mui/icons-material/Share';
import CheckIcon from '@mui/icons-material/Check';
import {forwardRef, useEffect, useState} from "react";

import Logger from "../../utils/Logger"
import {NodeType} from "../../model/Constants";
import {safeStringify} from "../../utils/JSONUtils";
import ConfirmDialog from "../ConfirmDialog/ConfirmDialog";
import {addClasses} from "../../utils/PresentationUtils";
import {useModel} from "../../model/ModelContext";
import {useWorkspaceContext} from "../../security/WorkspaceContext";
import {useSelectedNodes} from "../SelectedNodes/SelectedNodesProvider";
import {useCreateOrEditDialog} from "../DialogBoxes/CreateOrEditDialogProvider";
import {IdNameDescriptionObjectList} from "./IdNameDescriptionObjectList";
import {ClickableInlineObjectList} from "./ClickableInlineObjectList";

const LOGGER = new Logger("PropertyPane")

function canBeEdited(node) {
    if (!node) {
        return false
    }
    const typeString = node.type
    switch (typeString) {
        case NodeType.Folder.description:

            return node?.id?.indexOf("_") !== 0;

        case NodeType.Snapshot.description:
            return false;
        default:
            return true
    }
}
function canBeDeleted(node) {
    const typeString = node.type
    switch (typeString) {
        case NodeType.Folder.description:

            return node?.id?.indexOf("_") !== 0;

        case NodeType.Snapshot.description:
            return false;
        default:
            return true
    }
}

function ShareButton({workspaceId, node}) {

    const [iconState, setIconState] = useState('share'); // 'share', 'ok', 'transitioning'

    async function copyShareLinkToCilpboard(node) {
        LOGGER.debug("copying node share url to  the clipboard for node:", node)
        const id = node.id
        const shareLink = window.location.origin + `/share/${workspaceId}/${id}`

        if (iconState === 'share') {
            setIconState('ok');
            setTimeout(() => {
                setIconState('share');
            }, 1000); // Switch back to share icon after 1 second
        }

        try {
            await navigator.clipboard.writeText(shareLink);
            console.log('Text copied to clipboard');
        } catch (err) {
            console.error('Failed to copy text: ', err);
        }

    }


    return <span className={cssStyles.shareButtonSpan} >
        <Fade in={iconState === 'share'} timeout={500}>
            <div style={{ display: iconState === 'share' ? 'inline-block' : 'none' }}>
                <ShareIcon className={cssStyles.shareButtonIcon}
                           onClick={()=>{copyShareLinkToCilpboard(node)}}/>
            </div>
        </Fade>
        <Fade in={iconState === 'ok'} timeout={500}>
            <div style={{ display: iconState === 'ok' ? 'inline-block' : 'none' }}>
                <CheckIcon className={cssStyles.shareOkButtonIcon}/>
            </div>
        </Fade>
    </span>;
}
// eslint-disable-next-line
function PropertyPane({}, ref) {

    const createOrEditDialog = useCreateOrEditDialog()

    const [node, setNode] = useState({})

    const {getNodeById, saveNode, removeNodeById, updatedNode, reload} = useModel()
    const {selectedWorkspace} = useWorkspaceContext()
    const {nodeBeingEdited, selectedNodes, softSelectedNodes, updating, setSelectedNode} = useSelectedNodes()

    const jsonString = safeStringify(node, "node is probably circular")

    LOGGER.debug("PropertyPane.ENTER: (stringlength=" + jsonString?.length +"):", jsonString?.substring(0, 40))
    const [nodeSpecificProperties, setNodeSpecificProperties] = useState(<></>)
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)

    const getNodeNameById = (nodeId) => {
        return getNodeById(nodeId)?.name || "-"
    }

    let editButtonClickedHandler = (zeNode) => {
        createOrEditDialog.editNode(zeNode, (updatedNode)=>{
            LOGGER.debug("editNode.callback-onClose")
            //in some cases the zeNode will contain more fields than are in the updatedNode, so we need to merge them
            // as an example, the updatedNode will not contain the graph fields, but the zeNode will
            const mergedNode = {...zeNode, ...updatedNode}
            saveNode(mergedNode)
            reload()
        }, ()=> {
            LOGGER.debug("editNode.callback-onCancel")
        })
    }

    useEffect(()=>{
        LOGGER.debug("nodeBeingEdited changed: ", nodeBeingEdited)
        if (nodeBeingEdited) {
            LOGGER.debug("no ID, using the nodeBeingEdited directly: ", nodeBeingEdited)
            setNode(nodeBeingEdited)
        } else {
            LOGGER.debug("no nodeBeingEdited, value undefined or null")
        }
    }, [nodeBeingEdited])

    useEffect(() => {
        LOGGER.debug(`updating changed (${updating})`)
        if (updating?.nodeBeingUpdated && node.id === updating?.nodeBeingUpdated?.id) {
            /*
             * The node being displayed has been updated (potentially) by another component, let's update it here
             */
            LOGGER.debug("updating: ", updating)
            setNode(updating.nodeBeingUpdated)
        }
        // eslint-disable-next-line
    }, [updating]);

    useEffect(()=>{
        LOGGER.debug("selectedNodes changed: ", selectedNodes)
        if (selectedNodes.length >= 1) {
            LOGGER.debug("selectedNodes.length >= 1: ", selectedNodes.length)
            LOGGER.debug("setting node value to selectedNodes[0]: ", selectedNodes[0])
            setNode(selectedNodes[0])
        } else {
            LOGGER.debug("selectedNodes.length <= 0, setting node to null: ", selectedNodes.length)
            setNode(null)
        }
    }, [selectedNodes])

    useEffect(()=>{
        LOGGER.debug("softSelectedNodes changed: ", softSelectedNodes)
        if (softSelectedNodes.length >= 1 && !!softSelectedNodes[0]) {
            if (selectedNodes?.filter(n=>!!n).map(n=>n.id).includes(softSelectedNodes[0].id)) {
                LOGGER.debug("softSelectedNodes[0] is already in selectedNodes, not softselecting node.")
                return
            }
            setNode(softSelectedNodes[0])
        }
        /*
         * No need to add selectedNodes to the dependency array, as it will trigger a re-render of this component
         * when selectedNodes changes, and we are already setting the node to the first element of selectedNodes
         * in the selectedNodes useEffect (above this one). So, we don't need to do it here. Otherwise, we would
         * end up showing the selectedNode and then immediately showing the softSelectedNode, which would be
         * wrong.
         */
        // eslint-disable-next-line
    }, [softSelectedNodes])

    useEffect(() => {
        LOGGER.debug("updatedNode changed: ", updatedNode)
        if (node?.id && updatedNode?.id && node?.id === updatedNode?.id) {
            LOGGER.debug("node.id === updatedNode.id, setting node to updatedNode")
            setNode(updatedNode)
        }
        // eslint-disable-next-line
    }, [updatedNode]);

    useEffect(()=>{
        LOGGER.debug("node changed: ", node)
        function applicationUsageToString(applicationUsage) {

            if (!applicationUsage) {
                return "- -> - -> -"
            }

            const actorName = getNodeNameById(applicationUsage.actorId)
            const functionName = getNodeNameById(applicationUsage.functionalityId)
            const applicationName = getNodeNameById(applicationUsage.applicationId)
            return actorName + " -> " + functionName + " -> " + applicationName
        }
        LOGGER.debug("node?.type: ", node?.type)
        switch(node?.type) {
            case NodeType.ActorActivity.description:
                setNodeSpecificProperties(<>
                    {node?.actorId &&
                        <div key={"prop-actor-key"} className={cssStyles.propertyRow}>
                            <span className={cssStyles.label}>Actor</span> <span className={cssStyles.value}>{getNodeNameById(node?.actorId)}</span>
                        </div>
                    }
                    {node?.applicationId &&
                        <div key={"prop-application-key"} className={cssStyles.propertyRow}>
                            <span className={cssStyles.label}>Application</span> <span className={cssStyles.value}>{getNodeNameById(node?.applicationId)}</span>
                        </div>
                    }
                    {node?.businessProcessId &&
                        <div key={"prop-business-process-key"} className={cssStyles.propertyRow}>
                            <span className={cssStyles.label}>Business Process</span> <span className={cssStyles.value}>{getNodeNameById(node?.businessProcessId)}</span>
                        </div>
                    }
                </>)
                break
            case NodeType.Application.description:
                LOGGER.debug("node?.architectureBuildingBlockIds:", node?.architectureBuildingBlockIds)
                setNodeSpecificProperties(<>
                    <div key={"prop-master-data-objects-key"} className={cssStyles.propertyRow}>
                        <span className={cssStyles.label}>Master Data Objects</span>
                        <span className={cssStyles.value}>
                            <ClickableInlineObjectList idList={node?.masterDataObjectIds} />
                        </span>
                    </div>
                    {node?.strategicClassification &&
                        <div key={"prop-strategic-classification-key"} className={cssStyles.propertyRow}>
                            <span className={cssStyles.label}>Strategic Classification</span> <span className={cssStyles.value}>{node?.strategicClassification}</span>
                        </div>
                    }
                    {node?.confidentiality &&
                        <div key={"prop-confidentiality-key"} className={cssStyles.propertyRow}>
                            <span className={cssStyles.label}>Confidentiality</span> <span className={cssStyles.value}>{node?.confidentiality}</span>
                        </div>
                    }
                    {node?.integrity &&
                        <div key={"prop-integrity-key"} className={cssStyles.propertyRow}>
                            <span className={cssStyles.label}>Integrity</span> <span className={cssStyles.value}>{node.integrity}</span>
                        </div>
                    }
                    {node?.availability &&
                        <div key={"prop-availability-key"} className={cssStyles.propertyRow}>
                            <span className={cssStyles.label}>Availability</span> <span className={cssStyles.value}>{node.availability}</span>
                        </div>
                    }
                    {node?.rpo &&
                        <div key={"prop-rpo-key"} className={cssStyles.propertyRow}>
                            <span className={cssStyles.label}>RPO</span> <span className={cssStyles.value}>{node.rpo}</span>
                        </div>
                    }
                    {node?.rto &&
                        <div key={"prop-rto-key"} className={cssStyles.propertyRow}>
                            <span className={cssStyles.label}>RTO</span> <span className={cssStyles.value}>{node.rto}</span>
                        </div>
                    }
                    {node?.supportedCapabilityIds &&
                        <div key={"prop-supportedcapabilities-key"} className={cssStyles.propertyRow}>
                            <span className={cssStyles.label}>Supported Capabilities</span>
                            <span className={cssStyles.value}>
                                <ClickableInlineObjectList idList={node?.supportedCapabilityIds} />
                            </span>
                        </div>
                    }
                    {node?.lifeCycleStage &&
                        <div key={"prop-lifeCycleStage-key"} className={cssStyles.propertyRow}>
                            <span className={cssStyles.label}>Life Cycle Stage</span> <span className={cssStyles.value}>{node.lifeCycleStage}</span>
                        </div>
                    }
                    {node?.introductionCost>0 &&
                        <div key={"prop-introductionCost-key"} className={cssStyles.propertyRow}>
                            <span className={cssStyles.label}>Introduction Cost</span> <span className={cssStyles.value}>{node.introductionCost}</span>
                        </div>
                    }
                    {node?.yearlyCost>0 &&
                        <div key={"prop-yearlyCost-key"} className={cssStyles.propertyRow}>
                            <span className={cssStyles.label}>Yearly Cost</span> <span className={cssStyles.value}>{node.yearlyCost}</span>
                        </div>
                    }
                    {node?.supportedRateOfChange &&
                        <div key={"prop-supportedRateOfChange-key"} className={cssStyles.propertyRow}>
                            <span className={cssStyles.label}>Supported Rate of Change</span> <span className={cssStyles.value}>{node.supportedRateOfChange}</span>
                        </div>
                    }
                </>)
                break
            case NodeType.ArchitectureBuildingBlock.description:
                LOGGER.debug("node?.functionalityIds:", node?.functionalityIds)
                setNodeSpecificProperties(<>
                    {node?.functionalityIds &&
                        <div key={"prop-functionalities-key"} className={cssStyles.propertyRow}>
                            <span className={cssStyles.label}>Implemented Functionalities</span>
                            <span className={cssStyles.value}>
                                <ClickableInlineObjectList idList={node?.functionalityIds} />
                            </span>
                        </div>
                    }
                </>)
                break
            case NodeType.BusinessProcess.description:
                setNodeSpecificProperties(<>
                    {node?.supportedCapabilityIds &&
                        <div key={"prop-supported-capabilities-key"} className={cssStyles.propertyRow}>
                            <span className={cssStyles.label}>Supported Capabilities</span>
                            <span className={cssStyles.value}>
                                <ClickableInlineObjectList idList={node?.supportedCapabilityIds} />
                            </span>
                        </div>
                    }
                    {node?.applicationUsages &&
                    <div className={cssStyles.propertyRow}>
                        <span key={"prop-application-usages-key"} className={cssStyles.label}>Applications Usages</span>
                        <br/>
                        <span className={cssStyles.value}>{node?.applicationUsages?.map(au=>(<>{applicationUsageToString(au)}<br/></>))}</span>
                    </div>
                    }
                </>)
                break
            case NodeType.Capability.description:

                let tierDiv = <></>
                if (node?.tier === 0 || (node?.tier && Number.isInteger(node?.tier) && node?.tier>=0)) {
                    tierDiv = <div key={"prop-tier-key"} className={cssStyles.propertyRow}>
                        <span className={cssStyles.label}>Tier</span> <span
                        className={cssStyles.value}>{node.tier}</span>
                    </div>
                }
                let levelDiv = <></>
                if (node?.level === 0 || (node?.level && Number.isInteger(node?.level) && node?.level>=0)) {
                    levelDiv = <div key={"prop-level-key"} className={cssStyles.propertyRow}>
                        <span className={cssStyles.label}>Level</span> <span
                        className={cssStyles.value}>{node.level}</span>
                    </div>
                }
                setNodeSpecificProperties(<>
                    {tierDiv}
                    {levelDiv}
                </>)
                break
            case NodeType.DataExchange.description:
                setNodeSpecificProperties(<>
                    {node?.sourceApplicationId &&
                        <div key={"prop-sourceApplication-key"} className={cssStyles.propertyRow}>
                            <span className={cssStyles.label}>Source Application</span> <span className={cssStyles.value}>{getNodeNameById(node.sourceApplicationId)}</span>
                        </div>
                    }
                    {node?.targetApplicationId &&
                        <div key={"prop-targetApplication-key"} className={cssStyles.propertyRow}>
                            <span className={cssStyles.label}>Target Application</span> <span className={cssStyles.value}>{getNodeNameById(node.targetApplicationId)}</span>
                        </div>
                    }
                    {node?.dataObjectIds &&
                        <div key={"prop-functionalities-key"} className={cssStyles.propertyRow}>
                            <span className={cssStyles.label}>Data Objects</span><br/><span className={cssStyles.value}>
                            <ClickableInlineObjectList idList={node?.dataObjectIds} />
                            </span>
                        </div>
                    }
                    {node?.supportingMiddlewareIds &&
                        <div key={"prop-supportingMiddleware-key"} className={cssStyles.propertyRow}>
                            <span className={cssStyles.label}>Supporting Middleware</span><br/><span className={cssStyles.value}>
                            <ClickableInlineObjectList idList={node?.supportingMiddlewareIds} />
                        </span>
                        </div>
                    }
                </>)
                break
            case NodeType.DataFlow.description:
                setNodeSpecificProperties(<>
                    {node?.sourceApplicationId &&
                        <div key={"prop-sourceApplication-key"} className={cssStyles.propertyRow}>
                            <span className={cssStyles.label}>Source Application</span> <span className={cssStyles.value}>{getNodeNameById(node.sourceApplicationId)}</span>
                        </div>
                    }
                    {node?.targetApplicationId &&
                        <div key={"prop-targetApplication-key"} className={cssStyles.propertyRow}>
                            <span className={cssStyles.label}>Target Application</span> <span className={cssStyles.value}>{getNodeNameById(node.targetApplicationId)}</span>
                        </div>
                    }
                    {node?.dataObjectIds &&
                        <div key={"prop-functionalities-key"} className={cssStyles.propertyRow}>
                            <span className={cssStyles.label}>Data Objects</span><br/>
                            <span className={cssStyles.value}>
                                <ClickableInlineObjectList idList={node?.dataObjectIds} />
                            </span>
                        </div>
                    }
                    {node?.supportingMiddlewareIds &&
                        <div key={"prop-supportingMiddleware-key"} className={cssStyles.propertyRow}>
                            <span className={cssStyles.label}>Supporting Middleware</span><br/>
                            <span className={cssStyles.value}>
                                <ClickableInlineObjectList idList={node?.supportingMiddlewareIds} />
                            </span>
                        </div>
                    }
                </>)
                break
            case NodeType.DataObject.description:
                setNodeSpecificProperties(<>
                    {node?.dataOwnerId &&
                        <div key={"prop-dataOwner-key"} className={cssStyles.propertyRow}>
                            <span className={cssStyles.label}>Actor owning data</span> <span className={cssStyles.value}>{getNodeNameById(node.dataOwnerId)}</span>
                        </div>
                    }
                </>)
                break
            case NodeType.DesignDecision.description:
                setNodeSpecificProperties(<>
                    {node?.decision &&
                        <div key={"prop-decision-key"} className={cssStyles.propertyRow}>
                            <span className={cssStyles.label}>Decision</span> <span className={cssStyles.value}>{node.decision}</span>
                        </div>
                    }
                    {node?.rationale &&
                        <div key={"prop-rationale-key"} className={cssStyles.propertyRow}>
                            <span className={cssStyles.label}>Rationale</span> <span className={cssStyles.value}>{node.rationale}</span>
                        </div>
                    }
                    {node?.status &&
                        <div key={"prop-status-key"} className={cssStyles.propertyRow}>
                            <span className={cssStyles.label}>Status</span>
                            <span className={cssStyles.value}>{node.status}</span>
                        </div>
                    }
                    {node?.alternatives &&
                        <div key={"prop-alternatives-key"} className={cssStyles.propertyRow}>
                            <span className={cssStyles.label}>Alternatives</span>
                            <span className={cssStyles.value}>
                                <IdNameDescriptionObjectList objectList={node.alternatives} tooltipGenerator={(o)=>{
                                    return <div>
                                        <div className={cssStyles.tooltipNameDiv}>{o.name}</div>
                                        <div className={cssStyles.tooltipDescriptionDiv}>{o.description}</div>
                                        <div className={cssStyles.tooltipContributingFactorsDiv}>{o.contributingFactors}</div>
                                    </div>
                                }}/>
                            </span>
                        </div>
                    }
                    {node?.implications &&
                        <div key={"prop-implications-key"} className={cssStyles.propertyRow}>
                            <span className={cssStyles.label}>Implications</span>
                            <span className={cssStyles.value}>
                                <IdNameDescriptionObjectList objectList={node.implications}/>
                            </span>
                        </div>
                    }
                    {node?.tradeOffs &&
                        <div key={"prop-tradeOffs-key"} className={cssStyles.propertyRow}>
                            <span className={cssStyles.label}>Trade-offs</span>
                            <span className={cssStyles.value}>
                                <IdNameDescriptionObjectList objectList={node.tradeOffs}/>
                            </span>
                        </div>
                    }
                    {node?.risksAndMitigations &&
                        <div key={"prop-risksAndMitigations-key"} className={cssStyles.propertyRow}>
                            <span className={cssStyles.label}>Risks and Mitigations</span>
                            <span className={cssStyles.value}>
                                <IdNameDescriptionObjectList objectList={node.risksAndMitigations}/>
                            </span>
                        </div>
                    }
                    {node?.assumptions &&
                        <div key={"prop-assumptions-key"} className={cssStyles.propertyRow}>
                            <span className={cssStyles.label}>Assumptions</span>
                            <span className={cssStyles.value}>
                                <IdNameDescriptionObjectList objectList={node.assumptions}/>
                            </span>
                        </div>
                    }
                    {node?.personTakingDecision &&
                        <div key={"prop-personTakingDecision-key"} className={cssStyles.propertyRow}>
                            <span className={cssStyles.label}>Person Taking Decision</span>
                            <span className={cssStyles.value}>{node.personTakingDecision}</span>
                        </div>
                    }
                    {node?.dateTaken &&
                        <div key={"prop-dateTaken-key"} className={cssStyles.propertyRow}>
                            <span className={cssStyles.label}>Date Taken</span>
                            <span className={cssStyles.value}>{node.dateTaken}</span>
                        </div>
                    }
                    {node?.references &&
                        <div key={"prop-references-key"} className={cssStyles.propertyRow}>
                            <span className={cssStyles.label}>References</span>
                            <span className={cssStyles.value}><IdNameDescriptionObjectList objectList={node.references}/></span>
                        </div>
                    }
                    {node?.principleIds && node?.principleIds?.length !== 0 &&
                        <div key={"prop-principleIds-key"} className={cssStyles.propertyRow}>
                            <span className={cssStyles.label}>Principles</span>
                            <span className={cssStyles.value}>
                                <ClickableInlineObjectList idList={node?.principleIds} />
                            </span>
                        </div>
                    }
                    {node?.impactedApplicationIds && node?.impactedApplicationIds?.length !== 0 &&
                        <div key={"prop-impactedApplicationIds-key"} className={cssStyles.propertyRow}>
                            <span className={cssStyles.label}>Impacted Applications</span>
                            <span className={cssStyles.value}>
                                <ClickableInlineObjectList idList={node?.impactedApplicationIds} />
                            </span>
                        </div>
                    }
                </>)
                break
            case NodeType.Principle.description:
                setNodeSpecificProperties(<>
                    {node?.status &&
                        <div key={"prop-status-key"} className={cssStyles.propertyRow}>
                            <span className={cssStyles.label}>Status</span>
                            <span className={cssStyles.value}>{node.status}</span>
                        </div>
                    }
                    {node?.rationale &&
                        <div key={"prop-rationale-key"} className={cssStyles.propertyRow}>
                            <span className={cssStyles.label}>Rationale</span>
                            <span className={cssStyles.value}>{node.rationale}</span>
                        </div>
                    }
                    {node?.implications &&
                        <div key={"prop-implications-key"} className={cssStyles.propertyRow}>
                            <span className={cssStyles.label}>Implications</span>
                            <span className={cssStyles.value}>{node.implications}</span>
                        </div>
                    }

                    {node?.dateTaken &&
                        <div key={"prop-dateTaken-key"} className={cssStyles.propertyRow}>
                            <span className={cssStyles.label}>Date Taken</span>
                            <span className={cssStyles.value}>{node.dateTaken}</span>
                        </div>
                    }

                </>)
                break
            case NodeType.Scenario.description:
                setNodeSpecificProperties(<>
                    {node?.newApplicationIds?.length === 0 &&
                        <div key={"prop-applications-new"} className={cssStyles.propertyRow}>
                            <span className={cssStyles.label}>No new Applications</span>
                        </div>
                    }
                    {node?.newApplicationIds?.length > 0 &&
                        <div key={"prop-applications-new"} className={cssStyles.propertyRow}>
                            <span className={cssStyles.label}>New Applications</span>
                            <span className={cssStyles.value}>
                                <ClickableInlineObjectList idList={node?.newApplicationIds}/>
                            </span>
                        </div>
                    }
                    {node?.updatedApplicationIds?.length === 0 &&
                        <div key={"prop-applications-updated"} className={cssStyles.propertyRow}>
                            <span className={cssStyles.label}>No updated Applications</span>
                        </div>
                    }
                    {node?.updatedApplicationIds?.length > 0 &&
                        <div key={"prop-applications-updated"} className={cssStyles.propertyRow}>
                            <span className={cssStyles.label}>Updated Applications</span>
                            <span className={cssStyles.value}>
                                <ClickableInlineObjectList idList={node?.updatedApplicationIds}/>
                            </span>
                        </div>
                    }
                    {node?.deletedApplicationIds?.length === 0 &&
                        <div key={"prop-applications-deleted"} className={cssStyles.propertyRow}>
                            <span className={cssStyles.label}>No deleted Applications</span>
                        </div>
                    }
                    {node?.deletedApplicationIds?.length > 0 &&
                        <div key={"prop-applications-deleted"} className={cssStyles.propertyRow}>
                            <span className={cssStyles.label}>Deleted Applications</span>
                            <span className={cssStyles.value}>
                                <ClickableInlineObjectList idList={node?.deletedApplicationIds}/>
                            </span>
                        </div>
                    }
                    {node?.explanation &&
                        <div key={"prop-explanation-key"} className={cssStyles.propertyRow}>
                            <span className={cssStyles.label}>Explanation</span>
                            <span className={cssStyles.value} dangerouslySetInnerHTML={{__html: node?.explanation}}></span>
                        </div>
                    }
                </>)
                break
            case NodeType.View.description:
                setNodeSpecificProperties(<>
                    {node?.applicationComponents &&
                        <div key={"prop-application-components-key"} className={cssStyles.propertyRow}>
                            <span className={cssStyles.label}>Application Components</span>
                            <span className={cssStyles.value}>{node.applicationComponents?.map((ac)=>ac.name).join(", ")}</span>
                        </div>
                    }
                </>)
                break
            default:
                LOGGER.debug("node type not recognized, setting nodeSpecificProperties to empty div.")
                setNodeSpecificProperties(<></>)
                break
        }
        // eslint-disable-next-line
    }, [node, selectedNodes])

    let parentNode = getNodeById(node?.parentId)

        return <div className={cssStyles.main}>
            { node?.type &&
                <>
                    <div key={"prop-dialog-key"} className={cssStyles.propertyRow}>

                        {
                            canBeEdited(node) && <Button
                                name={"edit_button"}
                                variant="contained"
                                onClick={()=>{
                                    editButtonClickedHandler(node)
                                }}
                            >{"EDIT"}</Button>
                        }
                        {
                            canBeDeleted(node) && <span className={cssStyles.deleteButtonSpan} >
                                <DeleteIcon
                                    className={cssStyles.deleteButtonIcon}
                                    onClick={()=>{setShowDeleteConfirmation(true)}}
                                />
                            </span>
                        }
                        { //xxx
                            <ShareButton workspaceId={selectedWorkspace} node={node}/>
                        }
                        <ConfirmDialog
                            open={showDeleteConfirmation} title={"Sure you want to delete?"}
                            setOpen={(newValue)=>setShowDeleteConfirmation(newValue)}
                            onConfirm={()=> {
                                setSelectedNode(null)
                                removeNodeById(node?.id)
                                reload()

                            }}
                        >
                            Are you sure you want to delete "{node.name}"?
                        </ConfirmDialog>
                    </div>
                    <div key={"prop-id-key"} className={cssStyles.propertyRow}>
                        <span className={addClasses([cssStyles.label, cssStyles.idKey])}>ID</span> <span className={addClasses([cssStyles.value, cssStyles.idValue])}>{node?.id}</span>
                    </div>
                    <div key={"prop-type-key"} className={cssStyles.propertyRow}>
                        <span className={addClasses([cssStyles.label, cssStyles.typeKey])}>Type</span> <span className={addClasses([cssStyles.value, cssStyles.typeValue])}>{node?.type}</span>
                    </div>
                    <div data-test={"propertypane-name-div"} key={"prop-name-key"} className={cssStyles.propertyRow}>
                        <span className={addClasses([cssStyles.label, cssStyles.nameKey])}>Name</span> <span data-testid={"propertypane-name-value-span"} className={addClasses([cssStyles.value, cssStyles.nameValue])}>{node?.name}</span>
                    </div>
                    {node?.description &&
                    <div key={"prop-description-key"} className={cssStyles.propertyRow}>
                        <span className={cssStyles.label}>Description</span> <span className={cssStyles.value}>{node?.description}</span>
                    </div>
                    }
                    {parentNode && <div key={"prop-parent-name-key"} className={cssStyles.propertyRow}>
                            <span className={cssStyles.label}>Parent Name</span> <span
                            className={cssStyles.value}>{parentNode.name}</span>
                        </div>
                    }

                    {nodeSpecificProperties}

                </>
            }
            {(!node || !node.id) &&
                <div key={"prop-nothing-selected-key"} className={cssStyles.main}><span>Nothing selected.</span></div>
            }
        </div>

}
export default forwardRef(PropertyPane);
